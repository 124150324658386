import React from "react";
import GroupsList from "../../../../components/app/tool/groups/List";

type ScreenGroupsListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenGroupsList(props: ScreenGroupsListProps) {
	return <GroupsList />;
}

export default ScreenGroupsList;
