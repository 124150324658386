import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import Button from "@set-product/core/button";
import toolSelectors from "redux/app/tool/selectors";
import toolActions from "redux/app/tool/actions";
import {
	IIndividualGroup,
	ITenant,
	TGroupModeType,
} from "redux/app/tool/@types";
import ModalPortal from "components/app/_common/ModalPortal";
import GroupForm from "./_elements/GroupForm";
import { IListObjectStore } from "redux/_common/type";
import { IVessel } from "redux/app/feedback/@types";
import { commonUtils } from "redux/_common";
import ConfirmBox from "../configandprint/_elements/ConfirmBox";
import GroupTable from "./_elements/Table";
import { cloneDeep } from "lodash";
import { initialGroupDetails } from "redux/app/tool/reducers";

type OwnProps = {};
type GroupsListProps = PropsFromRedux & OwnProps;
function GroupsList(props: GroupsListProps) {
	const { isGroupModalVisible, mode, groupsIsConfirmModalOpen } = props;

	useEffect(() => {
		props.loadTenantsList();
		props.loadGroupsList();
		return () => {
			props.groupDetailsSet(cloneDeep(initialGroupDetails));
			props.setTenantsList(commonUtils.arrayToObject([]));
			props.setTenantSpecificVesselsList(commonUtils.arrayToObject([]));
			props.setIdOfGroupToBeUpdated("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="bg-white h-[calc(100vh-100px)] overflow-y-auto m-2 rounded-md">
			<div className="px-4 flex justify-between items-center pt-4">
				<div className="w-full flex flex-col  justify-center">
					<div className="w-full flex justify-between items-center mb-4">
						<h3 className="text-lg text-gray-600">Groups</h3>
						<Button
							onClick={() => {
								props.toggleGroupModal(true);
								props.setGroupModalMode("ADD");
								props.groupDetailsSet(cloneDeep(initialGroupDetails));
							}}
							className="bg-blue-600"
						>
							Add Group
						</Button>
					</div>
					<div className="w-full">
						<GroupTable />
					</div>
				</div>
			</div>
			{isGroupModalVisible ? (
				<ModalPortal
					isActive={isGroupModalVisible}
					isOverlayClickCloseable={false}
					onClose={() => {
						props.setGroupModalMode("NONE");
						props.toggleGroupModal(false);
					}}
					width={450}
				>
					<GroupForm mode={mode} />
				</ModalPortal>
			) : null}
			{groupsIsConfirmModalOpen && (
				<ConfirmBox
					title="Are you sure you want to delete this Group?"
					closeBtnLabel="Close"
					confirmBtnLabel="Confirm"
					isActive={groupsIsConfirmModalOpen}
					onClose={() => props.toggleConfirmBox(false)}
					onConfirm={() => {
						props.toggleConfirmBox(true);
					}}
				/>
			)}
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const isGroupModalVisible = toolSelectors.getIsGroupModalVisible(toolStore);
	const mode = toolSelectors.getGroupModeType(toolStore);
	const groupsIsConfirmModalOpen =
		toolSelectors.getGroupsIsConfirmBoxModalOpen(toolStore);
	return {
		isGroupModalVisible,
		mode,
		groupsIsConfirmModalOpen,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleGroupModal(isGroupModalVisible?: boolean) {
			dispatch(toolActions.document.groupModalToggle(isGroupModalVisible));
		},
		setGroupModalMode(mode: TGroupModeType) {
			dispatch(toolActions.document.groupModeSet(mode));
		},
		setTenantsList(tenants: IListObjectStore<ITenant>) {
			dispatch(toolActions.document.toolTenantListSet(tenants));
		},
		setTenantSpecificVesselsList(vessels: IListObjectStore<IVessel>) {
			dispatch(toolActions.document.toolTenantSpecificVesselsSet(vessels));
		},
		loadTenantsList() {
			dispatch(toolActions.commands.toolTenantListLoad());
		},
		toggleConfirmBox(isConfirmBoxModalOpen?: boolean) {
			dispatch(
				toolActions.document.groupConfirmBoxToggle(isConfirmBoxModalOpen)
			);
		},
		loadGroupsList(tenantId?: string) {
			dispatch(toolActions.commands.toolGroupsListLoad(tenantId));
		},
		groupDetailsSet(groupDetails: IIndividualGroup) {
			dispatch(toolActions.document.toolGroupsGroupDetailsSet(groupDetails));
		},
		setIdOfGroupToBeUpdated(groupId: string) {
			dispatch(toolActions.document.toolGroupsIdOfGroupToBeUpdated(groupId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GroupsList);
