import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { ITabReport } from "redux/app/logs/@types";
import { IStore } from "redux/store";

interface IReportRowProps {
	report: ITabReport;
	onClick(report: ITabReport): void;
	indexNumber: number;
	setErrorDetailsModalOpen: (value: any) => void;
	setErrorDetails: (value: any) => void;
}

export const getTitle = (report: ITabReport) => {
	return "CNR";
};

const getImosReportName = (reportType: string) => {
	switch (reportType) {
		case "STEAMING":
			return "Noon Report At Sea";
		case "PORT":
			return "Noon Report In Port";
		case "ARRIVAL":
			return "Arrival Notice";
		case "DEPARTURE":
			return "Departure Notice";

		default:
			return reportType;
	}
};

export const getSelectedDate = (report: ITabReport) => {
	if (report?.selectedDate?.includes("T")) {
		if (report?.intlDateLineRecord) {
			return `${report?.selectedDate?.split("T")?.[0]} (IDL Repeat)`;
		}
		return `${report?.selectedDate?.split("T")?.[0]}`;
	}

	return "";
};

export const getStatus = (report: ITabReport) => {
	if (report?.doc_status) {
		return `${report?.doc_status?.charAt(0)}${report?.doc_status
			?.slice(1)
			.toLowerCase()}`;
	}
	return "";
};

type ReportRowProps = PropsFromRedux & IReportRowProps;
function ReportRow(props: ReportRowProps) {
	const { report } = props;

	return (
		<tr
			key={report._id}
			className="my-1 bg-white border hover:bg-gray-200 hover:cursor-pointer"
		>
			<td className="text-center text-gray-400 px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				{props.indexNumber}
			</td>
			<td className="font-medium text-center text-gray-800 px-0.5 py-0.5 md:px-2 md:py-1 text-[7px] md:text-xs lg:text-sm">
				{getTitle(report)}
			</td>
			<td
				className="font-mono text-center text-gray-700 px-0.5 py-0.5 md:px-2 md:py-1 text-[6px] w-[110px] md:text-xs lg:text-sm"
				title={moment(report.createdAt).format("DD-MM-YYYY hh:mm a")}
			>
				{moment(report.createdAt).utc().format("DD-MM-YYYY hh:mm a")}
			</td>
			<td className="font-mono text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[6px] md:text-xs lg:text-sm ">
				{getSelectedDate(report)}
			</td>
			<td className="font-mono text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				{/* {report?.timeline
					?.map((item, index) => `${item?.report_type || ""}`)
					.toString()} */}

				{/* render cell for each report */}
				{/* {report?.timeline?.map((item, index) => (
					<div key={index} className="flex flex-col">
						<span>{item?.report_type}</span>
						<span className="text-xs text-gray-500">
							{item?.from_local_date} {item?.from_local_time}
						</span>
					</div>
				))} */}

				{/* render cell for each report type in a table like structure. Report Name | DateTime */}
				<table
					// style={{
					// 	width: "100%",
					// 	borderCollapse: "collapse",
					// 	borderSpacing: "0 5px",
					// 	border: "1px solid #e2e8f0",
					// }}
					className="mx-auto table-auto reportTimelineTable lg:table-fixed"
				>
					<tbody>
						{report?.timeline?.map((item, index) => (
							<tr className="h-full" key={index}>
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									{item?.report_type}
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									{getImosReportName(item.report_type)}
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{item?.to_local_date} {item?.to_local_time}{" "}
									{item?.to_zd}
								</td>
								<td
									className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
									title={`${
										report?.signalApiLastProcessedDate
											? `Last Processed at ${moment(
													report?.signalApiLastProcessedDate
											  ).format("DD-MM-YYYY hh:mm a")}`
											: ""
									}`}
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the signalApiResponse
										// and show the response in a dialog/modal
										const erroneousReport = item;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													erroneousReport?.report_type,
												errorDetails: {
													formID: item
														?.signalApiResponse?.id,
													message: item
														?.signalApiResponse
														?.detail
														? item
																?.signalApiResponse
																?.detail
														: JSON.stringify(
																item?.signalApiResponse
														  ),

													success: item
														?.signalApiResponse?.id
														? true
														: item
																?.signalApiResponse
																?.detail
														? false
														: undefined,
												},
												lastProcessedDate:
													report?.signalApiLastProcessedDate,
												reportType: "SIGNAL",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"Signal API Response not found in the report"
											);
										}
									}}
								>
									{/* TODO: remove report_id check later */}

									{item?.signalApiResponse
										? item?.signalApiResponse?.id ||
										  item?.signalApiResponse === 200
											? "✅"
											: "❌"
										: "⏳"}
								</td>
								<td
									className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
									title={`${
										item?.report_type === "CARGO"
											? "Not Applicable, will be sent with departure notice, if any"
											: report?.imosApiLastProcessedDate
											? `Last Processed at ${moment(
													report?.imosApiLastProcessedDate
											  ).format("DD-MM-YYYY hh:mm a")}`
											: ""
									}`}
									// on click of IMOS status, it should show the IMOS API response in a dialog/modal
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the imosApiResponse
										// and show the response in a dialog/modal
										const erroneousReport = item;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													erroneousReport?.report_type,
												errorDetails:
													erroneousReport?.imosApiResponse,
												lastProcessedDate:
													report?.imosApiLastProcessedDate,
												reportType: "IMOS",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"IMOS API Response not found in the report"
											);
										}
									}}
								>
									{/* if report type is CARGO then the status is not applicable to it, just show ➖ */}
									{item?.report_type === "CARGO"
										? "➖"
										: item?.imosApiResponse
										? item?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"}

									{/* {item?.imosApiResponse
										? item?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"} */}
								</td>
							</tr>
						))}

						{/* also check if there is standaloneForm available in which check for statementOfFacts and delayOffHireNotice forms  */}
						{/* example {standaloneForm: {statementOfFacts:{},delayOffHireNotice:{} }} */}

						{report?.standaloneForm?.statementOfFacts && (
							<tr className="h-full">
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									SOF
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									Statement of Facts
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_date
									}{" "}
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_time
									}{" "}
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_zd
									}
								</td>
								<td
									className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
									title={`Not Applicable`}
								>
									{/* INFO: we don't SOF to Signal, so show not applicable emoji */}
									➖
								</td>
								<td
									className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
									title={`${
										report?.standaloneForm?.statementOfFacts
											?.imosApiResponse
											? `Last Processed at ${moment(
													report?.standaloneForm
														?.statementOfFacts
														?.imosApiResponse
														?.lastProcessedDate
											  ).format("DD-MM-YYYY hh:mm a")}`
											: ""
									}`}
									// on click of IMOS status, it should show the IMOS API response in a dialog/modal
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the imosApiResponse
										// and show the response in a dialog/modal
										const erroneousReport =
											report?.standaloneForm
												?.statementOfFacts;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													"Statement of Facts",
												errorDetails:
													erroneousReport?.imosApiResponse,
												lastProcessedDate:
													report?.imosApiLastProcessedDate,
												reportType: "IMOS",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"IMOS API Response not found in the report"
											);
										}
									}}
								>
									{report?.standaloneForm?.statementOfFacts
										?.imosApiResponse
										? report?.standaloneForm
												?.statementOfFacts
												?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"}
								</td>
							</tr>
						)}

						{report?.standaloneForm?.delayOffHireNotice && (
							<tr className="h-full">
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									DELAY
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									Delay Offhire Notice
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_date
									}{" "}
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_time
									}{" "}
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_offset
									}
								</td>
								<td
									className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
									title={`Not Applicable`}
								>
									{/* INFO: we don't send Delay offhire to Signal, so show not applicable emoji */}
									➖
								</td>
								<td
									className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
									title={`${
										report?.standaloneForm
											?.delayOffHireNotice
											?.imosApiResponse
											? `Last Processed at ${moment(
													report?.standaloneForm
														?.delayOffHireNotice
														?.imosApiResponse
														?.lastProcessedDate
											  ).format("DD-MM-YYYY hh:mm a")}`
											: ""
									}`}
									// on click of IMOS status, it should show the IMOS API response in a dialog/modal
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the imosApiResponse
										// and show the response in a dialog/modal
										const erroneousReport =
											report?.standaloneForm
												?.delayOffHireNotice;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													"Delay Offhire Notice",
												errorDetails:
													erroneousReport?.imosApiResponse,
												lastProcessedDate:
													report?.imosApiLastProcessedDate,
												reportType: "IMOS",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"IMOS API Response not found in the report"
											);
										}
									}}
								>
									{report?.standaloneForm?.delayOffHireNotice
										?.imosApiResponse
										? report?.standaloneForm
												?.delayOffHireNotice
												?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</td>
			<td className="font-medium text-center text-gray-700 px-0.5 py-0.5 md:px-2 md:py-1 text-[8px] md:text-xs lg:text-sm">
				{getStatus(report)}{" "}
			</td>

			<td className="text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				<button
					className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700 text-[9px] md-text[12px] lg:text-sm"
					onClick={function onClick() {
						props.onClick(report);
					}}
				>
					View
				</button>
			</td>
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: IReportRowProps) {
	return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ReportRow);
