import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import InputWrapper from "components/_common/InputWrapper";
import Dropdown from "components/_common/Dropdown";
import InfiniteProgress from "components/_common/InfiniteProgress";

const vesselSubTypes = [
	{
		label: "All Vessel Types",
		value: "",
	},
	{
		label: "Bulk",
		value: "Bulk",
	},
	{
		label: "Tanker",
		value: "Tanker",
	},
	{
		label: "LNG",
		value: "LNG",
	},
	{
		label: "Container",
		value: "Container",
	},
	{
		label: "PCTC",
		value: "PCTC",
	},
];

type OwnProps = {
	isLoading?: boolean;
	showDropdownLabel?: boolean;
	selectedVesselSubType?: string;
	onVesselSubTypeSelect?(vesselType: string): void;
};
type VesselSelectorProps = PropsFromRedux & OwnProps;
function VesselSelector(props: VesselSelectorProps) {
	const {
		isLoading = false,
		showDropdownLabel = false,
		selectedVesselSubType = "",
		onVesselSubTypeSelect
	} = props;

	return (
		<div className="">
			<div>
				<InputWrapper
					className="w-full py-0 my-0"
					hasError={false}
					hasWarning={false}
					isLoading={isLoading}
				>
					{showDropdownLabel && (
						<label className="ws-input__label">Select a vessel type</label>
					)}
					<Dropdown
						inputWrapperProps={{}}
						id="vessel-list-id"
						disabled={isLoading}
						options={vesselSubTypes}
						value={selectedVesselSubType}
						placeholder="Select Vessel"
						onSelect={function onVesselTypeChange(e) {
							if (e) {
								if (onVesselSubTypeSelect) onVesselSubTypeSelect(e.value);
							}
						}}
						renderView={function renderView(selectedItem) {
							return (
								<span className="whitespace-no-wrap w-42 bp3-text-overflow-ellipsis">
									{selectedItem.label}&nbsp;
								</span>
							);
						}}
						noFilter={true}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
					<InfiniteProgress
						className="ws-input__progress"
						isLoading={true}
						isSmall={true}
						isSpacedOut={false}
					/>
				</InputWrapper>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselSelector);
