import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IOption } from "redux/app/feedback/@types";
import {
	clearIndicatorStyles,
	controlStyles,
	dropdownIndicatorStyles,
	menuStyles,
	multiValueStyles,
	optionStyles,
	placeholderStyles,
} from "redux/app/feedback/utils";
import { IIndividualGroup, TGroupModeType } from "redux/app/tool/@types";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import {
	getTenantsOptionList,
	getVesselsOptionList,
} from "redux/app/tool/utils";
import { IStore } from "redux/store";
import InputWrapper from "components/_common/InputWrapper";
import Button from "@set-product/core/button";

const initialGroupDetailsError = {
	groupName: "",
	tenantId: "",
	vessels: "",
};

type OwnProps = {
	mode: TGroupModeType;
};
type GroupFormProps = PropsFromRedux & OwnProps;
function GroupForm(props: GroupFormProps) {
	const {
		mode,
		tenants,
		vessels,
		jobToLoadVesselSpecificToTenant,
		groupDetails,
		jobToCreateGroup,
		jobToUpdateGroup,
	} = props;

	const isEditMode = mode === "EDIT";
	const { groupName, tenantId, vessels: groupBasedVessels } = groupDetails;
	const [groupDetailsError, setGroupDetailsError] = useState({
		...initialGroupDetailsError,
	});

	const groupBasedVesselIds = groupBasedVessels?.map((v) => v.vesselId);
	const tenantOptions = useMemo(
		() => getTenantsOptionList(tenants),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tenants.ids]
	);

	const selectedTenantOption = tenantOptions?.find(
		(option) => option.value === tenantId
	);

	const vesselsOptions = useMemo(
		() => getVesselsOptionList(vessels),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[vessels.ids]
	);

	const selectedVesselOptions = useMemo(() => {
		return groupBasedVessels?.map((v) => ({
			label: v.vesselName,
			value: v.vesselId,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupBasedVesselIds]);

	// Handle case when tenant is unselected.
	useEffect(() => {
		if (!selectedTenantOption?.value) {
			props.groupDetailsSet({ ...groupDetails, vessels: [] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption]);

	useEffect(() => {
		const tenantId = selectedTenantOption?.value;
		if (tenantId) {
			props.loadTenantSpecificVesselsList(tenantId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value]);

	const formTitle = mode === "ADD" ? "Add group" : "Update group";
	const isTenantError = !!groupDetailsError?.tenantId?.length;
	const isVesselsError = !!groupDetailsError?.vessels?.length;

	return (
		<div className="bg-white rounded-lg font-redhat-text p-5">
			<h3 className="text-lg text-gray-700 font-medium px-2 pb-2">
				{formTitle}
			</h3>
			<div className="m-2 mb-4">
				<InputWrapper
					className="w-full"
					hasError={!!groupDetailsError?.groupName?.length}
					hasWarning={false}
					inputValue={groupName}
				>
					<label
						htmlFor="auxiliaryengines-typeofgen"
						className="ws-input__label"
					>
						Group Name
					</label>
					<input
						type="text"
						name="auxiliaryengines-typeofgen"
						id="auxiliaryengines-typeofgen"
						className="ws-input bg-white"
						placeholder={"Group name"}
						value={groupName}
						onChange={(e) => {
							setGroupDetailsError({ ...groupDetailsError, groupName: "" });
							props.groupDetailsSet({
								...groupDetails,
								groupName: e.target.value,
							});
						}}
					/>
					<span className="ws-input__error">
						{groupDetailsError?.groupName}
					</span>
					<span className="ws-input__warning">Hello Warning</span>
					<span className="ws-input__help-message" />
				</InputWrapper>
			</div>
			<div className="m-2 mb-4">
				<h4
					className={classNames("text-gray-600 text-sm font-medium mb-2", {
						"text-red-800 ": isTenantError,
					})}
				>
					Select tenant
				</h4>
				<Select
					id="tenants"
					isClearable
					isSearchable
					key={"tenants" + selectedTenantOption?.value}
					placeholder={"Search tenants"}
					isDisabled={isEditMode}
					unstyled
					value={selectedTenantOption as IOption}
					options={tenantOptions}
					onChange={(newValue, actionMeta) => {
						setGroupDetailsError({ ...groupDetailsError, tenantId: "" });
						props.groupDetailsSet({
							...groupDetails,
							tenantId: newValue?.value as string,
						});
					}}
					className={classNames("w-full text-sm", {
						"cursor-not-allowed": false,
					})}
					menuPosition="fixed"
					classNames={{
						option: ({ isFocused, isSelected }) =>
							clsx(
								isFocused && optionStyles.focus,
								isSelected && optionStyles.selected,
								optionStyles.base
							),

						dropdownIndicator: () => dropdownIndicatorStyles,
						menu: () => menuStyles,
						clearIndicator: () => clearIndicatorStyles,
						placeholder: () => placeholderStyles,
						control: ({ isFocused, isDisabled }) =>
							clsx(
								isFocused ? controlStyles.focus : controlStyles.nonFocus,
								isDisabled ? controlStyles.disabled : controlStyles.base,
								isTenantError ? "border border-red-300" : ""
							),

						valueContainer: ({ isDisabled }) =>
							clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
					}}
				/>
				{isTenantError ? (
					<p className="text-xs mt-1 text-red-600 font-medium">
						{groupDetailsError?.tenantId}
					</p>
				) : null}
			</div>
			<div className="m-2 mb-4">
				<h4 className="text-gray-600 text-sm font-medium mb-2">
					Select Vessels
				</h4>
				<Select
					id="vessels"
					isMulti
					isClearable
					isSearchable
					key={
						"vessels" +
						selectedVesselOptions?.map((vessel) => vessel?.value).join("")
					}
					placeholder={"Search vessels"}
					isDisabled={
						!selectedTenantOption?.value ||
						jobToLoadVesselSpecificToTenant.isLoading
					}
					unstyled
					value={selectedVesselOptions as IOption[]}
					options={vesselsOptions}
					isLoading={jobToLoadVesselSpecificToTenant.isLoading}
					onChange={(newValue, actionMeta) => {
						setGroupDetailsError({ ...groupDetailsError, vessels: "" });
						props.groupDetailsSet({
							...groupDetails,
							vessels:
								newValue?.map((v) => ({
									vesselId: v.value,
									vesselName: v.label,
								})) ?? [],
						});
					}}
					className={classNames("w-full text-sm", {
						"cursor-not-allowed": false,
					})}
					menuPosition="fixed"
					classNames={{
						option: ({ isFocused, isSelected }) =>
							clsx(
								isFocused && optionStyles.focus,
								isSelected && optionStyles.selected,
								optionStyles.base
							),
						multiValue: () => multiValueStyles,
						dropdownIndicator: () => dropdownIndicatorStyles,
						menu: () => menuStyles,
						clearIndicator: () => clearIndicatorStyles,
						placeholder: () => placeholderStyles,
						control: ({ isFocused, isDisabled }) =>
							clsx(
								isFocused ? controlStyles.focus : controlStyles.nonFocus,
								isDisabled ? controlStyles.disabled : controlStyles.base,
								isVesselsError ? "border border-red-300" : ""
							),

						valueContainer: ({ isDisabled }) =>
							clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
					}}
				/>
				{isVesselsError ? (
					<p className="text-xs mt-1 text-red-600 font-medium">
						{groupDetailsError?.vessels}
					</p>
				) : null}
			</div>

			<div className="mt-16 flex justify-end space-x-4">
				<Button
					className="bg-blue-600 w-32 px-8"
					disabled={jobToCreateGroup?.isLoading || jobToUpdateGroup?.isLoading}
					onClick={() => {
						// Validate:
						const { groupName, tenantId, vessels } = groupDetails;
						const errorValues = { ...initialGroupDetailsError };
						if (!groupName && groupName.length < 3) {
							errorValues.groupName = !groupName.length
								? "Group name should not be empty"
								: "Group name should be atleast 3 characters";
						}
						if (!tenantId) {
							errorValues.tenantId = "Please select a tenant";
						}
						if (tenantId && !vessels?.length) {
							errorValues.vessels = "Please select atleast one vessel.";
						}
						if (
							Object.keys(errorValues)?.some(
								(key) => (errorValues as any)[key].length
							)
						) {
							setGroupDetailsError(errorValues);
							return;
						} else {
							setGroupDetailsError({ ...initialGroupDetailsError });
						}

						if (isEditMode) {
							props.groupUpdate();
						} else {
							props.groupCreate();
						}
					}}
				>
					{isEditMode ? "Update" : "Add"}
				</Button>
				<Button
					className="bg-white w-32 text-gray-600"
					onClick={() => {
						props.toggleGroupModal(false);
						props.setGroupModalMode("NONE");
					}}
				>
					Close
				</Button>
			</div>
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const groupDetails = toolSelectors.getFormGroupDetails(toolStore);
	const tenants = toolSelectors.getTenants(toolStore);
	const vessels = toolSelectors.getVessels(toolStore);
	const jobToLoadVesselSpecificToTenant =
		toolSelectors.jobToLoadVesselsSpecificToTenant(toolStore);
	const jobToCreateGroup = toolSelectors.jobToCreateGroup(toolStore);
	const jobToUpdateGroup = toolSelectors.jobToUpdateGroup(toolStore);
	return {
		tenants,
		vessels,
		groupDetails,
		jobToLoadVesselSpecificToTenant,
		jobToCreateGroup,
		jobToUpdateGroup,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadTenantSpecificVesselsList(tenantId: string) {
			dispatch(toolActions.commands.toolTenantSpecificVesselLoad(tenantId));
		},
		toggleGroupModal(isGroupModalVisible?: boolean) {
			dispatch(toolActions.document.groupModalToggle(isGroupModalVisible));
		},
		setGroupModalMode(mode: TGroupModeType) {
			dispatch(toolActions.document.groupModeSet(mode));
		},
		groupDetailsSet(groupDetails: IIndividualGroup) {
			dispatch(toolActions.document.toolGroupsGroupDetailsSet(groupDetails));
		},
		groupCreate() {
			dispatch(toolActions.commands.toolGroupsGroupCreate());
		},
		groupUpdate() {
			dispatch(toolActions.commands.toolGroupsGroupUpdate());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GroupForm);
