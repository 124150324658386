import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar } from "recharts";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { IStore } from "redux/store";
import CustomTooltip from "../../../common/CustomTooltip";
import reportActions from "redux/app/report/actions";
import { IComprehensiveNoonReportGraphData } from "redux/app/report/@types";


type OwnProps = {
	graphData: IComprehensiveNoonReportGraphData;
	reportContainerWidth: number;
};
type GraphProps = PropsFromRedux & OwnProps;
function Graph(props: GraphProps) {
	const { rawVesselSpecificComprehensiveNoonReportData } = props

	const {
		graphData,
		// vesselId,
		reportContainerWidth
	} = props;

	const legend = [{
		name: graphData.graphDataType,
		key: "value", // temp
		color: "#05a3e0",
	}];
	const graphTextSize = reportContainerWidth < 768 ? 10 : 12
	const graphBarSize = reportContainerWidth < 768 ? 15 : 50
	const graphInterval = reportContainerWidth < 768 ? 1 : 0

	//TEMP
	const graphDataTemp = rawVesselSpecificComprehensiveNoonReportData.slice(0, 7)

	useEffect(() => {
		if (graphData) props.loadComprehensiveNoonReportVesselSpecificData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphData]);

	return (
		<div className="flex flex-col justify-center m-2">
			<div className="p-4 px-6 shadow-md max-w-4xl rounded-md border border-gray-300">
				<h3 className="font-medium text-center py-1.5">
					Vessel Name - {legend[0].name} Chart
				</h3>
				<div className="flex flex-wrap p-4 pb-1.5">
					<div
						className="flex md:min-w-[180px] lg:min-w-[150px] xl:min-w-[170px] basis-1/3 text-sm space-x-1.5 py-0.5 items-center pl-1.5"
					>
						<span
							style={{ background: legend[0].color }}
							className="w-4 h-4 rounded-full"
						/>
						<div>
							<span>{legend[0].name}</span>
						</div>
					</div>
				</div>
				<div className="flex justify-center">
					<ResponsiveContainer
						minWidth={350}
						minHeight={450}
					>
						{graphData.isBarGraph ? (
							<BarChart
								data={graphDataTemp}
								margin={{
									top: 25,
									right: 50,
									bottom: 5,
									left: -10
								}}
								barSize={graphBarSize}
							>
								<CartesianGrid vertical={false} fill="" />
								<XAxis dataKey="date" tick={{ fontSize: graphTextSize }} interval={graphInterval} />
								<YAxis tick={{ fontSize: graphTextSize }} />
								<Tooltip
									content={
										<CustomTooltip legends={legend} metric={"mt"} />
									}
								/>
								<Bar dataKey="value" fill={legend[0].color} />
							</BarChart>
						) : (
							<LineChart
								data={graphDataTemp}
								margin={{
									top: 25,
									right: 50,
									bottom: 5,
									left: -10
								}}
							>
								<Line type="monotone" dataKey="value" stroke={legend[0].color} />
								<CartesianGrid stroke="#ddd" />
								<XAxis dataKey="date" tick={{ fontSize: graphTextSize }} interval={graphInterval} />
								<YAxis tick={{ fontSize: graphTextSize }} />
								<Tooltip
									content={
										<CustomTooltip legends={legend} metric={"mt"} />
									}
								/>
							</LineChart>
						)
						}

					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getGenericVesselId(reportStore);
	const rawVesselSpecificComprehensiveNoonReportData = reportSelectors.getRawVesselSpecificComprehensiveNoonReportData(reportStore);

	return {
		rawVesselSpecificComprehensiveNoonReportData,
		vesselId
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadComprehensiveNoonReportVesselSpecificData() {
			dispatch(reportActions.commands.comprehensiveNoonReportVesselSpecificDataLoad());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Graph);
