import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { IComprehensiveNoonReportFilters } from "redux/app/report/@types";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Dropdown from "components/_common/Dropdown";
import moment from "moment";
import { DateRange } from "react-date-range";
import { getComprehensiveNoonReportDataValueLimits } from "redux/app/report/utils";
import reportSelectors from "redux/app/report/selectors";
import reportActions from "redux/app/report/actions";
import InfiniteProgress from "components/_common/InfiniteProgress";
import InputWrapper from "components/_common/InputWrapper";

type OwnProps = {
	filterLabels: IComprehensiveNoonReportFilters[];
	isLoading?: boolean;
};
type ComprehensiveNoonReportTableFilters = PropsFromRedux & OwnProps;
function TableFilters(props: ComprehensiveNoonReportTableFilters) {
	const {
		rawComprehensiveNoonReportData,
		filterLabels,
		filters,
		setFilters,
		isLoading
	} = props

	useEffect(() => {
		resetAllFilters();

		return () => {
			resetAllFilters();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { maxValues, dropdownValues } = getComprehensiveNoonReportDataValueLimits(rawComprehensiveNoonReportData);

	const [isCollapsed, setIsCollapsed] = useState(true);
	const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

	const handleDateChange = (ranges: any) => {
		const { reportDate } = ranges;
		handleInputChange('reportDate', reportDate);
	};

	const handleInputChange = (key: string, value: any, preserveFilter = false) => {
		const updatedFilters = { ...filters };
		if (!value && !preserveFilter) {
			delete updatedFilters[key];
		} else {
			updatedFilters[key] = value;
		}
		setFilters(updatedFilters);
	};
	const handleReset = (key: string) => {
		const updatedFilters = { ...filters };
		delete updatedFilters[key];
		setFilters(updatedFilters);
	};
	const resetAllFilters = () => {
		setFilters({});
	}

	return (
		<InputWrapper
			className="w-full py-0 my-0"
			hasError={false}
			hasWarning={false}
			isLoading={isLoading}
		>
			<div className="flex">
				<button
					className={classNames(
						"text-gray-800 border border-gray-300 relative my-1 shadow-sm rounded-sm mb-1 text-sm font-light",
						{
							"min-w-28 w-36 md:w-56": isCollapsed,
							"w-full xl:w-2/3": !isCollapsed,
							"bg-gray-300": isLoading,
							"bg-blue-200": filters && (Object.keys(filters).length > 0),
							"bg-gray-100": !filters || (Object.keys(filters).length <= 0),
						}
					)}
					disabled={isLoading}
				>
					<div
						className="flex items-center justify-between p-2 cursor-pointer h-[37px]"
						onClick={() => setIsCollapsed((prev) => {
							if (!prev) setIsDateRangePickerOpen(false)
							return !prev
						})}
					>
						<span className={classNames(
							"text-sm",
							{ " text-gray-500": isLoading }
						)}>Filters</span>
						<div className="flex-col items-center justify-center px-1 my-0 border-l-0 rounded-b-none rounded-l-none pointer-events-none hover:bg-gray-200 focus:bg-gray-300 justify-items-center">
							<span className={classNames(
								"mb-px text-gray-600 text-md bp3-icon ",
								{
									"bp3-icon bp3-icon-caret-down": isCollapsed,
									"bp3-icon bp3-icon-caret-up": !isCollapsed,
								}
							)}
							/>
						</div>
					</div>
					{filters && Object.keys(filters).length > 0 && !isCollapsed &&
						<button
							className="text-sm absolute top-1 right-8 p-1 text-gray-600"
							onClick={resetAllFilters}
						>
							Clear Filters
						</button>
					}
					{!isCollapsed && (
						<div className="absolute p-4 z-10 bg-white border border-gray-400 shadow-sm border-t-0 w-full">
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
								{filterLabels.sort((a) => (a.type === "date") ? -1 : 1).map(({ label, key, type }) => (
									<div key={key} className="">
										<div className="flex justify-between items-center cursor-default">
											<label className={classNames(
												"block text-sm font-medium  mb-1",
												{
													"text-gray-700": !filters[key],
													"text-green-600": filters[key]
												}
											)}>
												{label}
											</label>
											{Object.prototype.hasOwnProperty.call(filters, key) &&
												<span
													onClick={() => handleReset(key)}
													title="reset"
													className="cursor-pointer text-gray-600 border-gray-300 bp3-icon bp3-icon-cross"
												>
												</span>
											}
										</div>
										<div className="relative h-10">
											{
												type === "number" ?
													<div className="flex flex-col gap-1">
														<input
															min={0}
															max={maxValues[key]}
															step={maxValues[key] > 100 ? Math.floor(maxValues[key] / 100) : 1}
															type="range"
															value={filters[key] || 0}
															onChange={(e) => handleInputChange(key, e.target.value)}
															className="w-full border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200"
														/>
														{Object.prototype.hasOwnProperty.call(filters, key) &&
															<div className="bg-gray-200 p-0.5 rounded-md flex items-center">
																<span>{'>='}</span>
																<input
																	type="text"
																	className="text-sm text-gray-800 bg-transparent w-full flex-grow"
																	value={filters[key]}
																	onChange={(e) => handleInputChange(key, e.target.value, true)}
																/>
															</div>
														}
													</div>
													: type === "date" ?
														<div
															className="flex flex-col justify-center items-center cursor-pointer"
														>
															<button
																className={classNames(
																	"ws-input flex justify-between items-center w-full my-2",
																	{ "text-gray-500": !(filters.reportDate?.startDate && filters.reportDate?.endDate) }
																)}
																onClick={() => setIsDateRangePickerOpen((prev) => !prev)}
															>
																{filters.reportDate?.startDate && filters.reportDate?.endDate ?
																	`${moment(filters.reportDate.startDate).format("DD-MM-YYYY")} to
													${moment(filters.reportDate.endDate).format("DD-MM-YYYY")}`
																	:
																	"Select Date Range"
																}
																<span className="bp3-icon bp3-icon-caret-down text-gray-600"></span>
															</button>
															{isDateRangePickerOpen &&
																<div className="border border-gray-300 shadow bg-white z-10">
																	<DateRange
																		ranges={[filters.reportDate || { startDate: undefined, endDate: undefined, key: "reportDate" }]}
																		onChange={handleDateChange}
																		moveRangeOnFirstSelection={false}
																		showPreview={true}
																		calendarFocus="backwards"
																		showDateDisplay={false}
																		dragSelectionEnabled={false}
																		preventSnapRefocus
																		maxDate={moment().toDate()}
																	/>
																</div>
															}

														</div>

														: type === "dropdown" && dropdownValues ?
															<Dropdown
																inputWrapperProps={{}}
																id={filters[key]}
																options={
																	Array.from(dropdownValues[key]).map((item) => {
																		return { label: item, value: item };
																	})
																}

																value={filters[key]}
																placeholder={`Select ${label}`}
																onSelect={(e) => {
																	if (e) {
																		handleInputChange(key, e.value)
																	}
																}}
																renderView={function renderView(selectedItem) {
																	return (
																		<span className="whitespace-no-wrap w-42 bp3-text-overflow-ellipsis">
																			{selectedItem.label}&nbsp;
																		</span>
																	);
																}}

															/>
															:
															<input
																type="text"
																placeholder={`Type ${label} Name`}
																value={filters[key] || ""}
																onChange={(e) => handleInputChange(key, e.target.value)}
																className="placeholder:text-gray-500 ws-input my-2 w-full p-2 border border-gray-300  focus:outline-none focus:ring focus:ring-blue-200"
																onKeyDown={(e) => {
																	if (e.key === "Enter")
																		setIsCollapsed(true);
																}}

															/>
											}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</button>
				{filters && Object.keys(filters).length > 0 && isCollapsed &&
					<button
						className="text-sm p-1 text-gray-600"
						onClick={resetAllFilters}
					>
						Clear Filters
					</button>
				}
			</div>
			<InfiniteProgress
				className="ws-input__progress min-w-28 w-36 md:w-56"
				isLoading={true}
				isSmall={true}
				isSpacedOut={false}
			/>
		</InputWrapper>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawComprehensiveNoonReportData = reportSelectors.getRawComprehensiveNoonReportData(reportStore);
	const filters = reportSelectors.getGenericFilters(reportStore);

	return {
		rawComprehensiveNoonReportData,
		filters,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setFilters(filters: Record<string, any>) {
			dispatch(reportActions.document.reportFiltersSet(filters));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(TableFilters);

