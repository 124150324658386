import SVGConfigPrintTool from "components/_common/icons/tool/SVGConfigPrintTool";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { historyActions } from "redux/_core/history";
import { IStore } from "redux/store";
import { rootRoutes } from "routes";

type OwnProps = {};
type ToolListProps = PropsFromRedux & OwnProps;
function ToolList(props: ToolListProps) {
	return (
		<div className="bg-white h-[calc(100vh-98px)] m-2 rounded-md overflow-y-auto">
			<div className="flex items-center space-y-2">
				<div
					className="p-4 m-4 border border-[#D8D8D8] rounded-xl inline-block max-w-[216px] cursor-pointer hover:shadow-md"
					onClick={() => props.goToConfigsAndPrints()}
				>
					<div className="flex flex-col items-center justify-center">
						<div className="px-2 py-4">
							<SVGConfigPrintTool />
						</div>
						<h4 className="text-sm font-medium">
							Config/Print Manager
						</h4>
						<p className="m-0 mt-2 text-[12px] text-[#666666] text-center">
							Manage configs and prints
						</p>
					</div>
				</div>
				<div
					className="p-4 m-4 border border-[#D8D8D8] rounded-xl inline-block max-w-[216px] cursor-pointer hover:shadow-md"
					onClick={() => props.goToTrainingCertificate()}
				>
					<div className="flex flex-col items-center justify-center">
						<div className="px-2 py-4">
							<div className="py-2.5">
								<span className='text-5xl text-gray-400 bp3-icon bp3-icon-id-number' />
							</div>
						</div>
						<h4 className="text-sm font-medium">Certificate Manager</h4>
						<p className="m-0 mt-2 text-[12px] text-[#666666] text-center">
							User Training Certificates
						</p>
					</div>
				</div>
				<div
					className="p-4 m-4 border border-[#D8D8D8] rounded-xl inline-block max-w-[216px] cursor-pointer hover:shadow-md"
					onClick={() => props.goToConfigsAndPrintsActivityLogger()}
				>
					<div className="flex flex-col items-center justify-center">
						<div className="px-2 py-4">
							<div className="py-2.5">
								<span className="text-5xl text-gray-400 bp3-icon bp3-icon-history" />
							</div>
						</div>
						<h4 className="text-sm font-medium">Activity log</h4>
						<p className="m-0 mt-2 text-[12px] text-[#666666] text-center">
							Activity log for configs/prints
						</p>
					</div>
				</div>
				<div
					className="p-4 m-4 border border-[#D8D8D8] rounded-xl inline-block max-w-[216px] cursor-pointer hover:shadow-md"
					onClick={() => {
						props.goTo("#groups",rootRoutes.children.app.children.tools.children.list.children.groups.url())
					}}
				>
					<div className="flex flex-col items-center justify-center">
						<div className="px-2 py-4">
							<div className="py-2.5">
								<span className="text-5xl text-gray-400 bp3-icon bp3-icon-people" />
							</div>
						</div>
						<h4 className="text-sm font-medium">Groups</h4>
						<p className="m-0 mt-2 text-[12px] text-[#666666] text-center">
							Create and manage groups
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToConfigsAndPrints() {
			dispatch(
				historyActions.push(
					"#configsAndPrints",
					rootRoutes.children.app.children.tools.children.list.children.configPrint.url()
				)
			);
		},
		goToConfigsAndPrintsActivityLogger() {
			dispatch(
				historyActions.push(
					"#configsAndPrintsActivityLog",
					rootRoutes.children.app.children.tools.children.list.children.configPrintActivityLog.url()
				)
			);
		},
		goToTrainingCertificate() {
			dispatch(
				historyActions.push(
					"#trainingCertificate",
					rootRoutes.children.app.children.tools.children.list.children.trainingCertificate.url()
				)
			);
		},
		goTo(reference:string,url:string){
			dispatch(
				historyActions.push(
					reference,
					url
				)
			)
		}
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ToolList);
