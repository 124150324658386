import ComprehensiveNoonReport from "components/app/report/comprehensive-noon-report/comprehensive-noon-report/ComprehensiveNoonReport";
import React from "react";

type ScreenComprehensiveNoonReportProps = React.PropsWithChildren<{}>;

function ScreenComprehensiveNoonReport(props: ScreenComprehensiveNoonReportProps) {
	return <ComprehensiveNoonReport />;
}

export default ScreenComprehensiveNoonReport;
