import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Table from "../../configandprint/_elements/Table";
import { GROUPS_PER_PAGE, GroupsHeaderList } from "redux/app/tool/constants";
import Td from "../../configandprint/_elements/Td";
import { Menu } from "@headlessui/react";
import toolActions from "redux/app/tool/actions";
import { IIndividualGroup, TGroupModeType } from "redux/app/tool/@types";
import toolSelectors from "redux/app/tool/selectors";
import moment from "moment";
import InfiniteProgress from "components/_common/InfiniteProgress";
import NumberedPagination from "components/_common/NumberedPagination";
import classNames from "classnames";

type OwnProps = {};
type GroupTableProps = PropsFromRedux & OwnProps;
function GroupTable(props: GroupTableProps) {
	const { groupList, tenants, jobToLoadGroupList, paginationForGroups } = props;
	const { currentPage } = paginationForGroups;
	const [currentShowMore, setCurrentShowMore] = useState<string | undefined>(
		undefined
	);

	useEffect(() => {
		if (currentShowMore) {
			const elem = document?.getElementById(currentShowMore);
			if (elem) elem?.scrollIntoView({ behavior: "smooth" });
		}
	}, [currentShowMore]);
	return (
		<>
			<InfiniteProgress
				isLoading={jobToLoadGroupList.isLoading}
				isSpacedOut={false}
				className=""
			/>

			<Table isLoading={false} isEmpty={false} headers={GroupsHeaderList}>
				{groupList?.ids
					?.slice(
						(currentPage - 1) * GROUPS_PER_PAGE,
						currentPage * GROUPS_PER_PAGE
					)
					.map((groupId, index) => {
						const group = groupList.byIds[groupId];
						const { groupName, vessels, createdAt, tenantId } = group;
						const tenantName = tenants?.byIds[tenantId]?.name ?? "NA";

						return (
							<tr key={groupId}>
								<Td className="py-2">
									{(currentPage - 1) * GROUPS_PER_PAGE + index + 1}
								</Td>
								<Td className="py-2">{tenantName}</Td>
								<Td className="py-2">
									{groupName}
									<br />
									{vessels?.length ? (
										<span className="text-gray-600">{` ${vessels.length} vessels`}</span>
									) : null}
								</Td>
								<Td className="py-2 max-w-[350px]">
									{vessels
										?.slice(0, groupId === currentShowMore ? undefined : 5)
										?.map((vessel) => {
											const { vesselId, vesselName } = vessel;
											return (
												<span
													key={vesselId}
													className="px-2 py-1 mx-1 my-0.5 inline-block text-[12px] bg-gray-300 text-gray-700 rounded cursor-pointer "
												>
													{vesselName}
												</span>
											);
										})}
									<div id={groupId}>
										<div
											onClick={() => {
												if (groupId !== currentShowMore) {
													setCurrentShowMore(groupId);
												} else setCurrentShowMore(undefined);
											}}
											className="px-2 inline-flex items-center cursor-pointer text-blue-500 "
										>
											<span className="text-sm">
												{groupId !== currentShowMore
													? "show more"
													: "show less"}
											</span>
											<span
												className={classNames(
													"inline-block bp3-icon  ml-1 mt-1  text-xs",
													{
														"bp3-icon-chevron-down":
															groupId !== currentShowMore,
														"bp3-icon-chevron-up": groupId === currentShowMore,
													}
												)}
											></span>
										</div>
									</div>
								</Td>
								<Td className="py-2">
									{moment(createdAt).format("Do MMM, YYYY")}
								</Td>
								<Td className="py-2 text-center">
									<Menu as="div" className="relative inline-block text-left ">
										<Menu.Button
											className={
												"  w-full px-4  text-sm font-medium   bg-opacity-20 hover:bg-opacity-30 "
											}
										>
											<span className="bp3-icon bp3-icon-more" />
										</Menu.Button>
										<Menu.Items
											className={
												"absolute right-0 mt-2 w-28 rounded p-0.5 origin-top-right bg-white divide-y divide-gray-100 border shadow-sm z-50"
											}
										>
											<Menu.Item>
												{({ active }) => (
													<div
														className={`block px-4 py-2 text-sm cursor-pointer rounded ${
															active &&
															"bg-blue-600  hover:text-blue-100 text-gray-900 "
														}`}
														onClick={() => {
															props.toggleGroupModal(true);
															props.setGroupModalMode("EDIT");

															const groupDetails: IIndividualGroup = {
																groupName,
																tenantId,
																vessels,
															};
															props.setIdOfGroupToBeUpdated(groupId);
															props.groupDetailsSet(groupDetails);
														}}
													>
														Edit
													</div>
												)}
											</Menu.Item>
											{/* <Menu.Item>
										{({ active }) => (
											<div
												className={`block px-4 py-2 text-sm cursor-pointer rounded ${
													active &&
													"bg-blue-600 hover:text-blue-100 text-gray-900 "
												}`}
												onClick={() => {
													props.toggleConfirmBox(true);
												}}
											>
												Delete
											</div>
										)}
									</Menu.Item> */}
										</Menu.Items>
									</Menu>
								</Td>
							</tr>
						);
					})}
			</Table>
			{groupList?.ids?.length > 0 ? (
				<NumberedPagination
					{...{
						...paginationForGroups,
						totalItems: paginationForGroups.totalItems || 0,
					}}
					itemIds={[]}
					onPageChange={function onPageChange(pageNumberFromPagination) {
						props.goToNewPage(pageNumberFromPagination);
					}}
					isPageLoading={jobToLoadGroupList.isLoading}
				/>
			) : null}
		</>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const tenants = toolSelectors.getTenants(toolStore);
	const groupList = toolSelectors.getToolGroupsList(toolStore);
	const paginationForGroups = toolSelectors.getPaginationForGroups(toolStore);
	const jobToLoadGroupList = toolSelectors.jobToLoadGroupList(toolStore);
	return {
		tenants,
		groupList,
		paginationForGroups,
		jobToLoadGroupList,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleGroupModal(isGroupModalVisible?: boolean) {
			dispatch(toolActions.document.groupModalToggle(isGroupModalVisible));
		},
		setGroupModalMode(mode: TGroupModeType) {
			dispatch(toolActions.document.groupModeSet(mode));
		},
		toggleConfirmBox(isConfirmBoxModalOpen?: boolean) {
			dispatch(
				toolActions.document.groupConfirmBoxToggle(isConfirmBoxModalOpen)
			);
		},
		groupDetailsSet(groupDetails: IIndividualGroup) {
			dispatch(toolActions.document.toolGroupsGroupDetailsSet(groupDetails));
		},
		setIdOfGroupToBeUpdated(groupId: string) {
			dispatch(toolActions.document.toolGroupsIdOfGroupToBeUpdated(groupId));
		},
		goToNewPage(pageNumber: number) {
			dispatch(
				toolActions.document.toolGroupsPaginationCurrentPageSet(pageNumber)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GroupTable);
