import Report from "components/app/report/Report";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { rootRoutes } from "routes";
import ScreenReportCategories from "./categories";
import ScreenIndividualReportType from "./waste/individualReportType";
import ScreenBilgeReport from "./waste/bilgeWater";
import ScreenORSReport from "./waste/ors";
import ScreenCargoSlop from "./waste/cargoSlop";
import ScreenOilSpillsInWater from "./other/oilspillsInWater";
import ScreenComprehensiveNoonReport from "./comprehensive-noon-report/comprehensive-noon-report";

const reportsRoutes = rootRoutes.children.app.children.reports.children;
type ScreenUserProps = React.PropsWithChildren<{}>;
function ScreenReport(props: ScreenUserProps) {
	return (
		<Report>
			<Switch>
				<Route
					exact
					path={reportsRoutes.categories.path}
					component={ScreenReportCategories}
				/>
				<Route
					exact
					path={
						reportsRoutes.categories.children.waste.reportForWasteBilgeWater
							.path
					}
					component={ScreenBilgeReport}
				/>
				<Route
					exact
					path={
						reportsRoutes.categories.children.waste.reportForWasteGarbage.path
					}
					component={ScreenIndividualReportType}
				/>
				<Route
					exact
					path={reportsRoutes.categories.children.waste.reportForWasteORS.path}
					component={ScreenORSReport}
				/>
				<Route
					exact
					path={
						reportsRoutes.categories.children.waste.reportForWasteCargoSlop.path
					}
					component={ScreenCargoSlop}
				/>
				<Route
					exact
					path={
						reportsRoutes.categories.children.other.reportForOilSpillsInWater
							.path
					}
					component={ScreenOilSpillsInWater}
				/>
				<Route
					exact
					path={
						reportsRoutes.categories.children.comprehensiveNoonReport.comprehensiveNoonReport
							.path
					}
					component={ScreenComprehensiveNoonReport}
				/>

				<Route render={RedirectComponent} />
			</Switch>
		</Report>
	);
}

function RedirectComponent() {
	return <Redirect to={reportsRoutes.categories.url()} />;
}
export default ScreenReport;
