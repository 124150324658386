import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import Modal from "components/app/_common/Modal";
import { IVesselOperation } from "redux/app/vessel/types";
import NewVessel from "../_elements/New";
import DropdownRadio from "components/_common/DropdownRadio";
import { vesselActions } from "redux/app/vessel";
import Switch from "components/_common/Switch";
import ModalPortal from "components/app/_common/ModalPortal";
import AddFleet from "./AddFleet";
import { EVesselView } from "./Home";
import classNames from "classnames";
import { authSelectors } from "redux/app/auth";

type OwnProps = {
	triggerAddVesselOperation: () => void;
	resetOperations: () => void;
	vesselOperation: IVesselOperation;
	vesselView: EVesselView;
	onVesselViewChange: (view: EVesselView) => void;
};
type HomeFleetNavigationProps = PropsFromRedux & OwnProps;
function HomeFleetNavigation(props: HomeFleetNavigationProps) {
	const {
		triggerAddVesselOperation,
		vesselOperation,
		resetOperations,
		fleets,
		selectedFleetId,
		isSelectionEnabled,
		isCreateFleetModalOpen,
		assignDDselectedFleetId,
		selectedVesselsOnEnableMode,
		isFleetsListDDOpen,
		isAssignVesselsToFleetDDOpen,
		setIsAssignVesselsToFleetDDOpen,
		setIsFleetsListDDOpen,
	} = props;

	useEffect(() => {
		if (!isSelectionEnabled) {
			props.resetEnableSelectedMode();
			props.setSelectedFleetIdOfAssignToFleetDD("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelectionEnabled]);

	return (
		<>
			<div className="flex flex-col items-center justify-between py-3 mx-2 border-b md:flex-row">
				<div className="flex flex-wrap items-center justify-center md:flex-nowrap md:justify-start">
					<div className="text-xl">
						{
							<DropdownRadio
								placement="overlap"
								topLabel="Select fleet"
								name="fleets"
								isOpen={isFleetsListDDOpen}
								handleOpen={() => setIsFleetsListDDOpen(true)}
								handleClose={() => setIsFleetsListDDOpen(false)}
								value={selectedFleetId}
								handleChange={(value) => {
									props.setSelectedFleetId(value);
									setIsFleetsListDDOpen(false);
								}}
								options={[
									{
										label: "All Vessels",
										value: "all",
									},
									...fleets.map((fleet) => ({
										label: fleet.name,
										value: fleet._id,
									})),
								]}
								bottomBar={() => {
									return (
										<div className="flex items-center justify-center py-2 border-t">
											<p
												onClick={() => {
													setIsFleetsListDDOpen(
														false
													);
													props.toggleCreateFleetModal();
												}}
												className="underline text-base text-[#133774] cursor-pointer"
											>
												Add Fleet
											</p>
										</div>
									);
								}}
								displayRenderer={(displayableSelectedValue) => {
									return (
										<div
											className="flex items-center text-sm cursor-pointer md:text-xl"
											onClick={() =>
												setIsFleetsListDDOpen(true)
											}
										>
											<p>
												{displayableSelectedValue?.length &&
													displayableSelectedValue[0]
														.label}
											</p>
											<span className="ml-2 bp3-icon bp3-icon-chevron-down" />
										</div>
									);
								}}
							/>
						}
						<ModalPortal
							isActive={isCreateFleetModalOpen}
							onClose={() => props.toggleCreateFleetModal()}
							width={477}
						>
							<AddFleet />
						</ModalPortal>
					</div>
					<div className="ml-8">
						<Switch
							id={"enableSelection"}
							label="Enable selection"
							isChecked={isSelectionEnabled}
							handleChange={(isChecked) => {
								props.enableVesselSelection(isChecked);
							}}
						/>
					</div>
					{isSelectionEnabled && (
						<div className="md:ml-20">
							<DropdownRadio
								horizontalOrientation="center"
								topLabel="Select fleet"
								name="fleets"
								placement="bottom"
								isOpen={isAssignVesselsToFleetDDOpen}
								handleOpen={() =>
									setIsAssignVesselsToFleetDDOpen(true)
								}
								handleClose={() =>
									setIsAssignVesselsToFleetDDOpen(false)
								}
								value={assignDDselectedFleetId}
								handleChange={(value) => {
									props.setSelectedFleetIdOfAssignToFleetDD(
										value
									);
								}}
								options={[
									...fleets.map((fleet) => ({
										label: fleet.name,
										value: fleet._id,
									})),
								]}
								bottomBar={() => {
									return (
										<div
											onClick={() => {
												if (
													assignDDselectedFleetId &&
													selectedVesselsOnEnableMode?.length
												) {
													props.assignSelectedVesselsToSelectedFleet();
													setIsAssignVesselsToFleetDDOpen(
														false
													);
												}
											}}
											className="py-2 flex justify-center items-center border-t bg-[#133774] absolute bottom-0 w-full rounded-b-md cursor-pointer"
										>
											<p className="text-base text-blue-100">
												Assign
											</p>
										</div>
									);
								}}
								displayRenderer={() => {
									return (
										<div
											className="flex items-center cursor-pointer"
											onClick={() =>
												setIsAssignVesselsToFleetDDOpen(
													true
												)
											}
										>
											<p className="underline text-[#133774] text-base md:text-lg ">
												Assign to a fleet
											</p>
											<span className="ml-1 bp3-icon bp3-icon-chevron-right" />
										</div>
									);
								}}
							/>
						</div>
					)}
				</div>
				{selectedFleetId === "all" && (
					<div className="flex items-center gap-x-4">
						<div className="flex items-center gap-x-2">
							<button
								onClick={() => {
									props.onVesselViewChange(EVesselView.CARD);
								}}
								className={classNames(
									"flex items-center justify-center border-none outline-none p-2 rounded-md",
									{
										"bg-[#EEEFFF]":
											props.vesselView ===
											EVesselView.CARD,
										"bg-transparent":
											props.vesselView !==
											EVesselView.CARD,
									}
								)}
							>
								<span className="text-lg bp3-icon bp3-icon-grid-view" />
							</button>
							<button
								onClick={() => {
									props.onVesselViewChange(EVesselView.TABLE);
								}}
								className={classNames(
									"flex items-center justify-center border-none outline-none p-2 rounded-md",
									{
										"bg-[#EEEFFF]":
											props.vesselView ===
											EVesselView.TABLE,
										"bg-transparent":
											props.vesselView !==
											EVesselView.TABLE,
									}
								)}
							>
								<span className="text-lg bp3-icon bp3-icon-th" />
							</button>
						</div>
						<button
							disabled={!props?.isUserAdmin}
							onClick={function onAddNewVesselClick() {
								triggerAddVesselOperation();
							}}
							className="mx-1 cursor-pointer font-medium  text-[#133774] shadow-none hover:text-blue-800 font-redhat-display text-sm md:text-base text underline mt-2 md:mt-0"
						>
							Add Vessel
						</button>
					</div>
				)}
			</div>
			<Modal
				isActive={vesselOperation.isModalOpen}
				onClose={() => resetOperations()}
			>
				{vesselOperation.isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							{vesselOperation.operation === "ADD_VESSEL" ? (
								<NewVessel
									onClose={function onCreateVesselModalClose() {
										resetOperations();
									}}
								/>
							) : null}
						</div>
					</div>
				) : null}
			</Modal>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;
	const authStore = store.app.auth;
	const isUserAdmin = authSelectors(authStore).isUserAdmin();
	return {
		fleets: vesselStore.fleets,
		selectedFleetId: vesselStore.selectedFleetId,
		isSelectionEnabled: vesselStore.enableSelection,
		isCreateFleetModalOpen: vesselStore.isCreateFleetModalOpen,
		assignDDselectedFleetId:
			vesselStore.selectedFleetIdToWhichVesselsToBeAssigned,
		selectedVesselsOnEnableMode:
			vesselStore.selectedVesselsOnEnableSelection,
		isFleetsListDDOpen: vesselStore.isFleetListDropdownOpen,
		isAssignVesselsToFleetDDOpen:
			vesselStore.isAssignVesselsToFleetDropdownOpen,
		isUserAdmin,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setSelectedFleetId(fleetId: string) {
			dispatch(vesselActions.selectedFleetIdSet(fleetId));
		},
		setSelectedFleetIdOfAssignToFleetDD(fleetId: string) {
			dispatch(vesselActions.assignToFleetDropdownIdSet(fleetId));
		},
		enableVesselSelection(isSelected: boolean) {
			dispatch(vesselActions.enableVesselSelection(isSelected));
		},
		resetEnableSelectedMode() {
			dispatch(vesselActions.selectedVesselsReset());
		},
		assignSelectedVesselsToSelectedFleet() {
			dispatch(vesselActions.assignSelectedVesselsToFleet());
		},
		toggleCreateFleetModal() {
			dispatch(vesselActions.createFleetModalToggle());
		},
		setIsFleetsListDDOpen(isOpen: boolean) {
			dispatch(vesselActions.fleetListDropdownToggle(isOpen));
		},
		setIsAssignVesselsToFleetDDOpen(isOpen: boolean) {
			dispatch(vesselActions.assignVesselToFleetDropdownToggle(isOpen));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(HomeFleetNavigation);
