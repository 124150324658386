import React from "react";
import { rootRoutes } from "../../../routes";
import { historyActions } from "../../../redux/_core/history";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteProps } from "react-router-dom";
import SVGVessel from "../../_common/icons/SVGVessel";
import SVGLogs from "../../_common/icons/SVGLog";
import classNames from "classnames";
import { IStore } from "redux/store";
import SVGBook from "components/_common/icons/SVGBook";
import SVGUsers from "components/_common/icons/SVGUsers";
import { authSelectors } from "redux/app/auth";
import SVGTools from "components/_common/icons/SVGTools";
import SVGReport from "components/_common/icons/SVGReport";

const { vessel, marpol, feedback, users, dashboard, tools, reports } =
	rootRoutes.children.app.children;

type HamburgerMenuType = PropsFromRedux &
	RouteProps & {
		push: (route: string) => void;
	};
function HamburgerMenu(
	props: HamburgerMenuType & { handleMobileNavbarClick?: () => void }
) {
	const {
		push,
		location,
		path,
		userEmail,
		handleMobileNavbarClick,
		isUserAllowedToAccessFeedbackModule,
	} = props;
	const links = [
		{
			Icon: SVGVessel,
			text: "Home",
			go: () => push(vessel.children.list.url()),
			path: vessel.path,
			isBluePrintIcon: false,
		},
		// {
		// 	Icon: SVGLogs,
		// 	text: "Logs",
		// 	go: () => push(log.children.list.url()),
		// 	path: log.path,
		// 	isBluePrintIcon: false,
		// },
		// {
		// 	Icon: SVGLogs,
		// 	text: "Approvals",
		// 	go: () => push(approval.children.list.url()),
		// 	path: approval.path,
		// 	isBluePrintIcon: false,
		// },
		// {
		// 	Icon: SVGLogs,
		// 	text: "Checklists",
		// 	go: () => push(checklist.childrens.list.url()),
		// 	path: checklist.path,
		// 	isBluePrintIcon: false,
		// },
		{
			Icon: SVGLogs,
			text: "Dashboard",
			go: () => push(dashboard.children.list.url()),
			path: dashboard.path,
			isBluePrintIcon: false,
		},
		{
			Icon: SVGReport,
			text: "Reports",
			go: () => push(reports.children.categories.url()),
			path: reports.children.categories.path,
			isBluePrintIcon: false,
			id: "reports-sidebar",
		}
	];

	if (isUserAllowedToAccessFeedbackModule) {
		links.push({
			Icon: SVGBook,
			text: "Feedback",
			go: () => push(feedback.children.list.url()),
			path: feedback.path,
			isBluePrintIcon: false,
		});
		links.push({
			Icon: SVGTools,
			text: "Tools",
			go: () => push(tools.children.list.url()),
			path: tools.path,
			isBluePrintIcon: false,
		});
	}

	links.push({
		Icon: SVGUsers,
		text: "Users",
		go: () => push(users.children.list.url()),
		path: users.path,
		isBluePrintIcon: false,
	});

	return (
		<div className="flex flex-col items-start min-w-24">
			{links.map((link, index) => {
				const isActive = location?.pathname.includes(link.path);
				return (
					<div
						key={index}
						onClick={() => {
							link.go();
							handleMobileNavbarClick && handleMobileNavbarClick();
						}}
						className={classNames(
							"flex items-center py-2 rounded-sm cursor-pointer ",
							{
								// "bg-blue-100 ": isActive,
								"bg-wayshipBlue": !isActive,
							}
						)}
					>
						<div className="flex justify-center w-12">
							<link.Icon
								className={classNames("w-8 h-8 rounded-full p-2", {
									"flex flex-row items-center justify-center select-none text-wayshipBlue":
										link.isBluePrintIcon,
									// "text-gray-600":
									// 	!isActive && !link.isBluePrintIcon,
									// "text-wayshipBlue":
									// 	isActive && !link.isBluePrintIcon,
									// "text-gray-500":
									// 	!isActive && link.isBluePrintIcon,
									// "text-gray-700":
									// 	isActive && link.isBluePrintIcon,
									"bg-blue-100": isActive,
									"text-blue-100": !isActive,
								})}
							/>
						</div>
						<div
							className={classNames(
								"text-sm md:text-md font-redhat-text select-none text-blue-100 font-medium py-2"
								// {
								// 	"text-blue-100 font-medium": isActive,
								// 	"text-gray-600 font-medium": !isActive,
								// }
							)}
						>
							{link.text}
						</div>
					</div>
				);
			})}
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const authStore = store.app.auth;
	const userEmail = authStore.user?.email;
	const isUserAllowedToAccessFeedbackModule =
		authSelectors(authStore).isUserAllowedToAccessModule("feedback");
	return {
		userEmail,
		isUserAllowedToAccessFeedbackModule,
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	push: (url: string) => dispatch(historyActions.push("HamburgerMenu", url)),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default withRouter(reduxConnector(HamburgerMenu));
