import { IComprehensiveNoonReportRecord } from "./@types";

const reportConstants = {
	FEATURE: "@report",
};

export const totalMonthsCount = 12;

export const garbageDischargeCategoriesMapper = {
	GARBAGE_DISCHARGED_INTO_SEA: "Discharged to sea",
	GARBAGE_INCINERATED: "Incinerated",
	GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS: "Disposed of Ashore",
	ACCIDENTAL: "Accidental",
};

export const orderOfDischargeCategories = [
	"GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
	"GARBAGE_DISCHARGED_INTO_SEA",
	"GARBAGE_INCINERATED",
	"ACCIDENTAL",
];

export const fillColors = {
	A: "#F4A460",
	B: "#003087",
	C: "#00A3E0",
	D: "#69D6FF",
	E: "#BA9040",
	F: "#685344",
	I: "#09C63E",
};

export const categoryLegends = [
	{ title: "Plastic", key: "A" },
	{ title: "Food waste", key: "B" },
	{ title: "Domestic waste", key: "C" },
	{ title: "Cooking oil", key: "D" },
	{ title: "Incinerated ashes", key: "E" },
	{ title: "Operation waste", key: "F" },
	{ title: "Electronic waste", key: "I" },
] as { title: string; key: keyof typeof fillColors }[];

export const wasteCategories = [...Object.keys(fillColors)];

// TODO: generated programatically rather hard coding.
export const quarterMonthMapper: Record<string, number[]> = {
	"Qtr 1": [0, 1, 2],
	"Qtr 2": [3, 4, 5],
	"Qtr 3": [6, 7, 8],
	"Qtr 4": [9, 10, 11],
};

export const defaultMonthRange = "0,11";

export const quarterWiseMonthRangeMapper = {
	"Qtr 1": "0,2",
	"Qtr 2": "3,5",
	"Qtr 3": "6,8",
	"Qtr 4": "9,11",
};

export const quarterLabels = ["Qtr 1", "Qtr 2", "Qtr 3", "Qtr 4"];

export const comprehensiveNoonReportKeyTypes = {
	dates: [
		"reportDate"
	],
	strings: [
		"vessel",
	],
	dropdowns: [
		"doc_status", 
		"status",
		"condition"
	]
}

export const comprehensiveNoonReportAllVesselsHeaders: Record<string, keyof IComprehensiveNoonReportRecord> = {
	"Vessel": "vessel",
	"Latest Report Status": "doc_status",
	"Days since last noon report": "daysSinceLastReport",
	"Days since last verification": "daysSinceVerificationPending",
	"Report Date (LT)": "reportDate",
	"Voyage": "voyage",
	"Status": "status",
	"Condition": "condition",
	"Elapsed Hours": "elapsedHours",
	"Distance (nm)": "distance",
	"SOG (knots)": "sog",
	"STW (knots)": "stw",
	"RPM": "rpm",
	"Beaufort": "beaufort",
	"VLSFO ROB (mt)": "vlsfoRob",
	"MGO ROB (mt)": "mgoRob",
	"IFO ROB (mt)": "ifoRob",
	"ULSFO ROB (mt)": "ulsfoRob",
	"LNG ROB": "lngRob",
	"Ethane ROB": "ethaneRob",
	"LPGB ROB": "lpgbRob",
	"LPGP ROB": "lpgpRob",
	"Bio LNG ROB": "biolngRob",
	"Methanol ROB": "methanolRob",
	"Bio Blend ROB": "bioBlendRob",
	"Reported Consumption (mt)": "reportedConsumption",
};

export default reportConstants;

