import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Button from "../../../../../@set-product/core/button";
import DeviceCreate from "./_elements/New";
import DeviceEdit from "./_elements/Edit";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../../redux/app/vessel/onboard";
import { IStore } from "../../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import InfiniteProgress from "../../../../_common/InfiniteProgress";
import { Icon, Popover } from "@blueprintjs/core";
import { commonUtils } from "../../../../../redux/_common";
import ConfirmBox from "../../../../_common/ConfirmBox";
import Modal from "../../../_common/Modal";
import authSelectors from "redux/app/auth/selectors";

type VesselOnboardDeviceProps = {
	vesselId: string;
} & PropsFromRedux;
function VesselOnboardDevice(props: VesselOnboardDeviceProps) {
	const { vesselId, devicesListData } = props;
	const { devices, jobs, onboardStatus } = devicesListData;
	const { deleteDeviceJob, listLoadJob } = jobs;

	useEffect(function onLoad() {
		props.resetDeviceList();
		props.loadDevices(vesselId);
	}, []);

	const [deviceOperation, setDeviceOperation] = useState<{
		deviceId: string | null;
		vesselId: string;
		operation: "ADD_DEVICE" | "UPDATE_DEVICE";
		isModalOpen: boolean;
	}>({
		vesselId,
		operation: "ADD_DEVICE",
		deviceId: null,
		isModalOpen: false,
	});

	function triggerAddDeviceOperation() {
		setDeviceOperation({
			...deviceOperation,
			deviceId: null,
			operation: "ADD_DEVICE",
			isModalOpen: true,
		});
	}

	function triggerUpdateDeviceOperation(deviceId: string) {
		setDeviceOperation({
			...deviceOperation,
			isModalOpen: true,
			operation: "UPDATE_DEVICE",
			deviceId,
		});
	}

	function resetOperations() {
		setDeviceOperation({
			...deviceOperation,
			isModalOpen: false,
			operation: "ADD_DEVICE",
			deviceId: null,
		});
	}
	return (
		<div className="">
			<div className="flex flex-row items-end justify-between my-6">
				<div>
					<h3 className="text-xl font-medium text-gray-700 font-redhat-display">
						Device onboarding
					</h3>
					{listLoadJob.isLoading ? (
						<h6 className="text-sm text-gray-600">
							Loading devices...
						</h6>
					) : (
						<h6 className="text-sm text-gray-600">
							{devices.ids.length} devices available
						</h6>
					)}
				</div>
				<div className="flex flex-row items-end justify-end h-full -mx-1">
					<Button
						view="filled"
						color="primary"
						rightIcon="plus"
						onClick={function onAddNewDeviceClick() {
							triggerAddDeviceOperation();
						}}
						disabled={!props?.isUserAdmin}
						className="mx-1 font-medium text-white bg-blue-600 shadow-none hover:bg-blue-800 font-redhat-display"
					>
						Add new Device
					</Button>
				</div>
			</div>
			<div
				className={classNames({
					"h-auto opacity-100 transition-all duration-300 animate-pulse":
						listLoadJob.isLoading && devices.ids.length === 0,
					"h-0 opacity-0 transition-all duration-300 animate-pulse":
						!listLoadJob.isLoading,
				})}
			>
				<InfiniteProgress
					isLoading={listLoadJob.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{
							"animate-pulse": listLoadJob.isLoading,
							hidden: !listLoadJob.isLoading,
						}
					)}
				>
					<thead>
						<tr>
							<th className="text-sm">Type</th>
							<th className="text-sm">Device Name</th>
							<th className="text-sm">OS</th>
							<th className="text-sm">Version</th>
							<th className="text-sm">Device Id</th>
							<th className="text-sm">MAC address</th>
							<th className="text-sm">
								<span>Actions</span>
							</th>
						</tr>
					</thead>
				</table>
				{listLoadJob.isLoading ? (
					<div className="flex-col justify-center h-64 py-24 bg-gray-100 ">
						<h4 className="-my-3 text-2xl text-center font-redhat-display">
							Loading please wait..
						</h4>
					</div>
				) : null}
			</div>
			<div className="mx-0 overflow-x-auto">
				{devices.ids.length > 0 ? (
					<table
						className={classNames(
							"w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive"
						)}
					>
						<thead>
							<tr>
								<th className="text-sm">Type</th>
								<th className="text-sm">Device Name</th>
								{/* <th className="text-sm">OS</th>
								<th className="text-sm">Version</th> */}
								<th className="text-sm">Device Id</th>
								<th className="text-sm">MAC address</th>
								<th className="text-sm">
									<span>Actions</span>
								</th>
							</tr>
						</thead>
						<tbody className={classNames({})}>
							{devices.ids.map(function listDevices(deviceId) {
								const device = devices.byIds[deviceId];
								return (
									<tr key={deviceId}>
										<td className="">
											<div className="text-gray-600 bg-transparent border-none shadow-none">
												<Icon
													icon={
														device.deviceType ===
														"DESKTOP"
															? "desktop"
															: "mobile-phone"
													}
													className="mb-1 text-gray-600"
												/>
												<span className="ml-3 text-gray-600 text-md">
													{commonUtils.capitalize(
														device.deviceType
													)}
												</span>
											</div>
										</td>
										<td className="font-medium">
											{device.deviceName}
										</td>
										{/* <td>{device.operatingSystem}</td>
										<td>{device.version}</td> */}
										<td className="w-1/4">
											<div className="flex flex-col">
												<div className="flex w-full text-xs">
													{device.deviceId}
												</div>
											</div>
										</td>
										<td className="font-mono text-gray-600">
											{device.macAddress}
										</td>
										<td>
											<Button
												text="Edit"
												dense={true}
												view="flat"
												color="primary"
												className="p-5 py-4 mx-1 font-medium hover:shadow-none hover:bg-blue-500 hover:text-white"
												onClick={function onEditDeviceClick() {
													triggerUpdateDeviceOperation(
														device.id
													);
												}}
												disabled={!props?.isUserAdmin}
											/>
											<Popover
												className="rounded-lg"
												canEscapeKeyClose={true}
												content={
													<ConfirmBox
														description={`Do you really want to delete device named name ${device.deviceName}?`}
														title="Confirm Deletion"
														primaryText="Delete"
														secondaryText="Cancel"
														state="ACTIVE"
														onPrimaryAction={function onDeleteCrewClick() {
															props.onDeviceDeleteClick(
																device.id
															);
														}}
													/>
												}
												target={
													<Button
														disabled={
															!props?.isUserAdmin
														}
														dense={true}
														view="flat"
														color="primary"
														rightIcon="cross"
														className="p-5 py-4 mx-1 font-medium text-gray-500 hover:shadow-none hover:bg-orange-500 hover:text-white"
													/>
												}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				) : !listLoadJob.isLoading ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-xl font-semibold text-gray-500 font-redhat-display">
							No Devices present
						</h5>
						<p className="p-3 text-sm leading-5 text-gray-700">
							You can onboard devices by clicking on <br />
							<strong className="px-2 font-medium">
								Add new Device
							</strong>
						</p>
					</div>
				) : null}
			</div>
			<Modal
				isActive={!!deviceOperation.isModalOpen}
				onClose={() => resetOperations()}
			>
				{deviceOperation.isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							{deviceOperation.operation === "ADD_DEVICE" ? (
								<DeviceCreate
									vesselId={deviceOperation.vesselId}
									onClose={function onCreateVesselModalClose() {
										resetOperations();
									}}
								/>
							) : null}
							{deviceOperation.operation === "UPDATE_DEVICE" ? (
								deviceOperation.deviceId !== null ? (
									<DeviceEdit
										deviceId={deviceOperation.deviceId}
										onClose={function onUpdateVesselModalClose() {
											resetOperations();
										}}
										onCancel={function onUpdateDeviceModalClose() {
											resetOperations();
										}}
									/>
								) : (
									<span>No Device Id Provided to edit</span>
								)
							) : null}
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

const { deviceListLoad, deviceDelete } = vesselOnboardActions.command;
const mapStateToProps = (store: IStore) => {
	const devicesListData = vesselOnboardSelectors.getDevicesListData(
		store.app.vesselStore.onboard
	);
	const authStore = store.app.auth;
	const isUserAdmin = authSelectors(authStore).isUserAdmin();
	return {
		devicesListData,
		isUserAdmin,
	};
};
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadDevices(vesselId: string) {
			dispatch(deviceListLoad(vesselId));
		},
		onDeviceDeleteClick(deviceId: string) {
			dispatch(deviceDelete(deviceId));
		},
		resetDeviceList() {
			dispatch(vesselOnboardActions.document.deviceListReset());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselOnboardDevice);
