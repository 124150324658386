import { IAPIRequestMetaData } from "redux/_core/api/types";
import { getType } from "typesafe-actions";
import reportActions from "./actions";
import {
	IBilgeWaterRecord,
	IGarbageDischargeAllVesselsRecord,
	IGarbageRawRecord,
} from "./@types";

const reportAPIS = {
	loadGarbageReport(
		tenantId: string,
		vesselId: string,
		yearRange: string,
		monthRange: string
	): IAPIRequestMetaData<IGarbageRawRecord[]> {
		// tenantId = "434d9b50-5703-11eb-8d4a-6b516664bdbd";
		return {
			url: `https://analytics.wayship.io/api/v1/marpol-grb/${tenantId}/vessels`,
			feature: getType(reportActions.commands.garbageReportLoad),
			method: "GET",
			queryParams: {
				vesselId,
				yearRange,
				monthRange,
			},
		};
	},
	loadGarbageTypeForAllVessels(
		tenantId: string,
		type: "dischargeMethod" | "garbageCategory",
		year: string,
		monthRange: string
	): IAPIRequestMetaData<IGarbageDischargeAllVesselsRecord[]> {
		return {
			url: `https://analytics.wayship.io/api/v1/marpol-grb/${tenantId}`,
			feature: getType(reportActions.commands.garbageDischargeAllVesselsLoad),
			method: "GET",
			queryParams: {
				year,
				monthRange,
				type,
			},
		};
	},
	loadBilgeWaterReport(
		tenantId: string,
		vesselId: string,
		yearRange: string,
		monthRange: string
	): IAPIRequestMetaData<IBilgeWaterRecord[]> {
		return {
			url: `https://analytics.wayship.io/api/v1/marpol-bilge/${tenantId}/vessels`,
			feature: getType(reportActions.commands.bilgeWaterReportLoad),
			method: "GET",
			queryParams: {
				vesselId,
				yearRange,
				monthRange,
			},
		};
	},
	loadBilgeWaterAllVesselsAggregate(
		tenantId: string,
		year: string,
		monthRange: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/marpol-bilge/${tenantId}`,
			feature: getType(
				reportActions.commands.bilgeWaterAllVesselsAggregateLoad
			),
			method: "GET",
			queryParams: {
				year,
				monthRange,
			},
		};
	},
	loadOrsAllVesselsAggregate(
		tenantId: string,
		year: string,
		monthRange: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/ors/${tenantId}`,
			feature: getType(reportActions.commands.orsAllVesselAggregateLoad),
			method: "GET",
			queryParams: {
				year,
				monthRange,
			},
		};
	},
	loadOrsVesselSpecificAggregate(
		tenantId: string,
		year: string,
		monthRange: string,
		vesselId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/ors/${tenantId}/vessel`,
			feature: getType(reportActions.commands.orsVesselSpecificAggregateLoad),
			method: "GET",
			queryParams: {
				yearRange: year,
				monthRange,
				vesselId,
			},
		};
	},
	loadCargoSlopAllVesselsAggregate(
		tenantId: string,
		year: string,
		monthRange: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/cargo-slop/${tenantId}`,
			feature: getType(reportActions.commands.cargoSlopAllVesselAggregateLoad),
			method: "GET",
			queryParams: {
				year,
				monthRange,
			},
		};
	},
	loadCargoSlopVesselSpecificAggregate(
		tenantId: string,
		year: string,
		monthRange: string,
		vesselId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/cargo-slop/${tenantId}/vessel`,
			feature: getType(
				reportActions.commands.cargoSlopVesselSpecificAggregateLoad
			),
			method: "GET",
			queryParams: {
				yearRange: year,
				monthRange,
				vesselId,
			},
		};
	},
	loadOilSpillsInWaterAllVesselsAggregate(
		tenantId: string,
		year: string,
		monthRange: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/oil-spill/${tenantId}`,
			feature: getType(
				reportActions.commands.oilSpillsInWaterAllVesselAggregateLoad
			),
			method: "GET",
			queryParams: {
				year,
				monthRange,
			},
		};
	},
	loadOilSpillsInWaterVesselSpecificAggregate(
		tenantId: string,
		year: string,
		monthRange: string,
		vesselId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://analytics.wayship.io/api/v1/oil-spill/${tenantId}/vessel`,
			feature: getType(
				reportActions.commands.oilSpillsInWaterVesselSpecificAggregateLoad
			),
			method: "GET",
			queryParams: {
				yearRange: year,
				monthRange,
				vesselId,
			},
		};
	},
	loadComprehensiveNoonReportAllVessels(
		//-----Temporary
		// tenantId: string,
		// vesselType: string,
		// vesselClass: string
	): IAPIRequestMetaData<any> {
		return {
			url: `https://console.wayship.io/api/cnrstatus`, //-----To be changed
			feature: getType(
				reportActions.commands.comprehensiveNoonReportAllVesselLoad
			),
			method: "GET",
			// queryParams: {
			// 	tenantId,
			// 	vesselType,
			// 	vesselClass
			// },
		};
	},
	loadComprehensiveNoonReportVesselSpecificData(
		//-----Temporary
		// tenantId: string,
		// dayRange: string
		// vesselId: string,
	): IAPIRequestMetaData<any> {
		return {
			url: `https://676e4d47df5d7dac1cca3775.mockapi.io/api/v1/cnr-`, //-----To be changed
			feature: getType(
				reportActions.commands.comprehensiveNoonReportVesselSpecificDataLoad
			),
			method: "GET",
			// queryParams: {
			// 	tenantId,
			// vesselId: string,
			// },
		};
	},
};

export default reportAPIS;
