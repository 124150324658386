import React from "react";
import classNames from "classnames";
import { IComprehensiveNoonReportGraphData, IComprehensiveNoonReportRecord } from "redux/app/report/@types";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import reportActions from "redux/app/report/actions";

enum TableDataKeys {
	SNo = "snumber",
	Vessel = "vessel",
	LatestReportStatus = "doc_status",
	DaysSinceLastReport = "daysSinceLastReport",
	DaysSinceVerificationPending = "daysSinceVerificationPending",
	ReportDate = "reportDate",
	Voyage = "voyage",
	Status = "status",
	Condition = "condition",
	ElapsedHours = "elapsedHours",
	Distance = "distance",
	SOG = "sog",
	STW = "stw",
	RPM = "rpm",
	Beaufort = "beaufort",
	VLSFORob = "vlsfoRob",
	MGORob = "mgoRob",
	IFORob = "ifoRob",
	ULSFORob = "ulsfoRob",
	LNGRob = "lngRob",
	EthaneROB = "ethaneRob",
	LPGBROB = "lpgbRob",
	LPGPROB = "lpgpRob",
	BioLNGROB = "biolngRob",
	MethanolROB = "methanolRob",
	BioBlendROB = "bioBlendRob",
	ReportedConsumption = "reportedConsumption",
}

type TableDataItem = {
	key: string;
	value: string | number;
};
type OwnProps = {
	columns: IComprehensiveNoonReportRecord;
	graphData: IComprehensiveNoonReportGraphData;
	setGraphData: any;
	index: number;
};
type ComprehensiveNoonReportTableColumnProps = PropsFromRedux & OwnProps;
function TableColumns(props: ComprehensiveNoonReportTableColumnProps) {

	const {
		index,
		columns,
		onReportClick,
		setVesselId,
		graphData,
		setGraphData
	} = props;

	const {
		vesselId,
		vessel,
		doc_status,
		daysSinceLastReport,
		daysSinceVerificationPending,
		reportDate,
		voyage,
		status,
		condition,
		elapsedHours,
		distance,
		sog,
		stw,
		rpm,
		beaufort,
		vlsfoRob,
		mgoRob,
		ifoRob,
		ulsfoRob,
		lngRob,
		ethaneRob,
		lpgbRob,
		lpgpRob,
		biolngRob,
		methanolRob,
		bioBlendRob,
		reportedConsumption,
	} = columns;

	// Needs to be in the order of the table headers (in Table component)
	const tableData: TableDataItem[] = [
		{ key: TableDataKeys.SNo, value: index + 1 },
		{ key: TableDataKeys.Vessel, value: vessel },
		{ key: TableDataKeys.LatestReportStatus, value: doc_status },
		{ key: TableDataKeys.DaysSinceLastReport, value: daysSinceLastReport?.toFixed(1) },
		{ key: TableDataKeys.DaysSinceVerificationPending, value: daysSinceVerificationPending?.toFixed(1) },
		{ key: TableDataKeys.ReportDate, value: reportDate },
		{ key: TableDataKeys.Voyage, value: voyage },
		{ key: TableDataKeys.Status, value: status },
		{ key: TableDataKeys.Condition, value: condition },
		{ key: TableDataKeys.ElapsedHours, value: elapsedHours },
		{ key: TableDataKeys.Distance, value: distance },
		{ key: TableDataKeys.SOG, value: sog },
		{ key: TableDataKeys.STW, value: stw },
		{ key: TableDataKeys.RPM, value: rpm },
		{ key: TableDataKeys.Beaufort, value: beaufort },
		{ key: TableDataKeys.VLSFORob, value: vlsfoRob },
		{ key: TableDataKeys.MGORob, value: mgoRob },
		{ key: TableDataKeys.IFORob, value: ifoRob },
		{ key: TableDataKeys.ULSFORob, value: ulsfoRob },
		{ key: TableDataKeys.LNGRob, value: lngRob },
		{ key: TableDataKeys.EthaneROB, value: ethaneRob },
		{ key: TableDataKeys.LPGBROB, value: lpgbRob },
		{ key: TableDataKeys.LPGPROB, value: lpgpRob },
		{ key: TableDataKeys.BioLNGROB, value: biolngRob },
		{ key: TableDataKeys.MethanolROB, value: methanolRob },
		{ key: TableDataKeys.BioBlendROB, value: bioBlendRob },
		{ key: TableDataKeys.ReportedConsumption, value: reportedConsumption?.toFixed(2) },
	];

	const setTableColumnColor = (key: string, value: any) => {
		switch (key) {
			case TableDataKeys.DaysSinceVerificationPending:
			case TableDataKeys.DaysSinceLastReport: {
				return classNames({
					"bg-yellow-500": value >= 1 && value < 1.5,
					"bg-green-500": value < 1 || !value,
					"bg-red-500": value >= 1.5,
				});
			}
			case TableDataKeys.LatestReportStatus: {
				return classNames({
					"text-yellow-500": value === "SUBMITTED",
					"text-blue-500": value === "APPROVED",
					"text-green-500": value === "VERIFIED",

				})
			}
			case TableDataKeys.Status: {
				const statusClass: Record<string, string> = {
					"Adrift": "bg-red-300",
					"Anchor": "bg-gray-400",
					"EOSP": "bg-gray-500",
					"Berthing": "bg-green-500",
					"BerthAlongside": "bg-green-400",
					"Shifting": "bg-green-300",
					"Steaming": "bg-blue-600",
					"Intransit": "bg-blue-400",
					"Unberthing": "bg-blue-300"
				};
				const status = statusClass[value];

				return classNames(
					status ? status + " text-white" : "text-black"
				);
			}
			case TableDataKeys.Condition: {
				return classNames({
					"text-blue-500": value === "Port",
					"text-yellow-500": value === "Laden",
					"text-green-500": value === "Ballast",
				});
			}
			case TableDataKeys.ReportedConsumption: {
				return "bg-lightGray";
			}
			default:
				return "text-[#2D2D2D]";
		}
	};

	const handleTableClick = (key: string) => {
		switch (key) {
			case TableDataKeys.DaysSinceLastReport:
			case TableDataKeys.DaysSinceVerificationPending:
			case TableDataKeys.LatestReportStatus:
			case TableDataKeys.Voyage:
			case TableDataKeys.ReportDate:
			case TableDataKeys.Status:
			case TableDataKeys.Condition:
				break;

			case TableDataKeys.Vessel: {
				const vesselSpecificReports = rootRoutes.children.app.children.vessel.children.onboard.children.reports;
				onReportClick(vesselSpecificReports.url(vesselId))
				break;
			}
			case TableDataKeys.ReportedConsumption:
				setGraphData({
					...graphData,
					graphDataType: key,
					isBarGraph: true,
					isModalVisible: true
				})
				setVesselId(vesselId);
				break;
			default: {
				setGraphData({
					...graphData,
					graphDataType: key,
					isBarGraph: false,
					isModalVisible: true
				})
				setVesselId(vesselId);
			}
		}
	}

	return (
		<>
			{tableData.map(({ key, value }: TableDataItem) => (
				<td
					key={key}
					className={classNames(
						"px-1 text-center py-3 text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text",
						setTableColumnColor(key, value)
					)}
					onClick={() => handleTableClick(key)}
				>
					{value ? value : "-"}
				</td>
			))}
		</>
	);
}


function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		onReportClick(routeUrl: string) {
			dispatch(historyActions.push("#VesselOnboard", routeUrl));
		},
		setVesselId(vesselId: string) {
			dispatch(reportActions.document.reportVesselIdSet(vesselId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(TableColumns);

