import { IStore } from "redux/store";
import { IToolStore } from "./@types";
import { CONFIG_TABS, PRINT_TABS } from "./constants";
import { jobSelectors } from "redux/_core/job";
import { getType } from "typesafe-actions";
import toolActions from "./actions";

const toolSelectors = {
	_getStore(store: IStore) {
		return store.app.tool;
	},
	getConfigPrintToolType(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.toolType;
	},
	getActiveTabForConfig(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.activeTab ?? CONFIG_TABS.TAB_LOGS;
	},
	getSelectedItems(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.selectedItems ?? [];
	},
	getIsCopyModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.isCopyModalOpen;
	},
	getIsConfirmBoxModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.isConfirmBoxOpen;
	},
	getIsUpdateModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.isUpdateModalOpen;
	},
	getIsConfigAddModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.isConfigAddModalOpen;
	},
	getIsConfigViewModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.isViewModalOpen;
	},
	getTenants(toolStore: IToolStore) {
		return toolStore?.tenants;
	},
	getVessels(toolStore: IToolStore) {
		return toolStore?.vessels;
	},
	getVesselSpecificUsers(toolStore: IToolStore) {
		return toolStore?.vesselSpecificUsers;
	},
	getConfigCopyModalVessels(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.vessels;
	},
	getApproveCertificate(toolStore: IToolStore) {
		return toolStore?.approveCertificate;
	},
	getSelectedTenantOption(toolStore: IToolStore) {
		return toolStore?.selectedTenantOption;
	},
	getSelectedVesselOption(toolStore: IToolStore) {
		return toolStore?.selectedVesselOption;
	},
	getConfigCopySelectedTenantOption(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.selectedTenantOption;
	},
	getConfigCopySelectedGroupOption(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.selectedGroupOption;
	},
	getConfigCopySelectedVesselOptions(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.selectedVesselOptions;
	},
	getConfigLogsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.logs?.logsList;
	},
	getConfigChecklistsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.checklists?.checklistsList;
	},
	getConfigPermitsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.permits?.permitsList;
	},
	getPaginationForLogs(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.logs._pagination;
	},
	getPaginationForChecklists(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.checklists._pagination;
	},
	getPaginationForPermits(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.permits._pagination;
	},
	getConfigInfo(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.configInfo;
	},
	getConfigErrorMessage(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.errorMessage;
	},
	getActionConfigId(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.onActionClickSelectedConfigId;
	},
	getSearchInputValue(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.searchInput;
	},
	getIsConfigFormExpanded(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.config?.isConfigFormExpanded;
	},
	getActiveTabForPrint(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.activeTab ?? PRINT_TABS.TAB_LOGS;
	},
	getPrintSelectedItems(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.selectedItems ?? [];
	},
	getPrintIsCopyModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.isCopyModalOpen;
	},
	getPrintIsUpdateModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.isUpdateModalOpen;
	},
	getIsPrintAddModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.isPrintAddModalOpen;
	},
	getIsPrintViewModalOpen(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.isViewModalOpen;
	},
	getPrintCopyModalVessels(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.vessels;
	},
	getPrintCopySelectedTenantOption(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.selectedTenantOption;
	},
	getPrintCopySelectedGroupOption(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.selectedGroupOption;
	},
	getPrintCopySelectedVesselOptions(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.selectedVesselOptions;
	},
	getPrintLogsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.logs?.logsList;
	},
	getPrintChecklistsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.checklists?.checklistsList;
	},
	getPrintPermitsList(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.permits?.permitsList;
	},
	getPrintPaginationForLogs(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.logs._pagination;
	},
	getPrintPaginationForChecklists(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.checklists._pagination;
	},
	getPrintPaginationForPermits(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.permits._pagination;
	},
	getPrintInfo(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.printInfo;
	},
	getPrintErrorMessage(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.errorMessage;
	},
	getActionPrintId(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.onActionClickSelectedPrintId;
	},
	getIsPrintFormExpanded(toolStore: IToolStore) {
		return toolStore?.configAndPrint?.print?.isPrintFormExpanded;
	},
	getCPActivityLog(toolStore: IToolStore) {
		return toolStore?.toolCPActivityLog?.configPrintsActivityLog;
	},
	getCPCardActivityLog(toolStore: IToolStore) {
		return toolStore?.toolCPActivityLogCard?.cardActivityLogList ?? [];
	},
	getPaginationOfCPActivityLog(toolStore: IToolStore) {
		return toolStore?.toolCPActivityLog?._pagination;
	},
	getPaginationOfCPCardActivityLog(toolStore: IToolStore) {
		return toolStore?.toolCPActivityLogCard?._pagination;
	},
	getIsGroupModalVisible(toolStore: IToolStore) {
		return toolStore?.groups?.isGroupModalVisible;
	},
	getGroupModeType(toolStore: IToolStore) {
		return toolStore?.groups?.mode;
	},
	getGroupsIsConfirmBoxModalOpen(toolStore: IToolStore) {
		return toolStore?.groups?.isConfirmBoxModalOpen;
	},
	getToolGroupsList(toolStore: IToolStore) {
		return toolStore?.groups?.groupList;
	},
	getFormGroupDetails(toolStore: IToolStore) {
		return toolStore?.groups?.groupDetails;
	},
	getIdOfTheGroupToBeUpdated(toolStore: IToolStore) {
		return toolStore?.groups?.idOfGroupToBeUpdated;
	},
	getPaginationForGroups(toolStore: IToolStore) {
		return toolStore?.groups?._pagination;
	},
	jobToAddTenantSpecificTrainingUsersFromCSV(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolTenantSpecificAddTrainingUsersFromCSV)
		);
	},
	jobToLoadTenants(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolTenantListLoad)
		);
	},
	jobToLoadVesselSpecificUsers(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolVesselSpecificUsersLoad)
		);
	},
	jobToLoadVesselsSpecificToTenant(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolTenantSpecificVesselLoad)
		);
	},
	jobToLoadConfig(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolConfigListLoad)
		);
	},
	jobToLoadConfigInfo(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolConfigLoad)
		);
	},
	jobToUpdateConfig(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolConfigUpdate)
		);
	},
	jobToCreateConfig(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolConfigCreate)
		);
	},
	jobToCopyConfig(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolConfigCopy)
		);
	},
	jobToLoadPrint(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore, //TODO: needs to be change with print command
			getType(toolActions.commands.toolPrintListLoad)
		);
	},
	jobToLoadPrintInfo(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolPrintLoad)
		);
	},
	jobToUpdatePrint(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolPrintUpdate)
		);
	},
	jobToCreatePrint(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolPrintCreate)
		);
	},
	jobToCopyPrint(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolPrintCopy)
		);
	},
	jobToLoadCPActivityLogs(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolCPActivityLogLoad)
		);
	},
	jobToLoadCount(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolCountListLoad)
		);
	},
	jobToLoadGroupList(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolGroupsListLoad)
		);
	},
	jobToCreateGroup(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolGroupsGroupCreate)
		);
	},
	jobToUpdateGroup(toolStore: IToolStore) {
		return jobSelectors.getJobStatus(
			toolStore,
			getType(toolActions.commands.toolGroupsGroupUpdate)
		);
	},
};

export default toolSelectors;
