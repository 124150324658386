import { Children } from "react";
import IRouteFormat from "./IRouteFormat";

const rootRoutes = {
	path: "/",
	url: () => "/",
	children: {
		auth: {
			path: "/auth",
			url: () => "/auth",
			isOnSidebar: false,
			authRequired: false,
			children: {
				login: {
					path: "/auth/login",
					url: () => "/auth/login",
				},
				register: {
					path: "/auth/register",
					url: () => "/auth/register",
				},
				reset: {
					path: "/auth/reset/:accessToken",
					url: (accessToken: string) => `/auth/reset/${accessToken}`,
				},
				reset_request: {
					path: "/auth/reset-request",
					url: () => "/auth/reset-request",
				},
				saml: {
					path: "/auth/saml",
					url: () => "/auth/saml"
				},
			},
		},
		app: {
			path: "/app",
			url: () => "/app",
			isOnSidebar: true,
			authRequired: true,
			children: {
				log: {
					path: "/app/log",
					url: () => "/app/log",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/log/list/:pageNumber",
							url: (pageNumber: number = 1) => `/app/log/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
						view: {
							path: "/app/log/view/:logId",
							url: (logId: string) => `/app/log/view/${logId}`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				vessel: {
					path: "/app/vessel",
					url: () => "/app/vessel",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/vessel/list/:pageNumber",
							url: (pageNumber = 1) => `/app/vessel/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
						onboard: {
							path: "/app/vessel/onboard/:vesselId",
							url: (vesselId: string) => `/app/vessel/onboard/${vesselId}`,
							isOnSidebar: true,
							authRequired: true,
							children: {
								view: {
									path: "/app/vessel/onboard/:vesselId/view/",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/view`,
									authRequired: true,
								},
								crew: {
									path: "/app/vessel/onboard/:vesselId/crew/",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/crew`,
									authRequired: true,
								},
								device: {
									path: "/app/vessel/onboard/:vesselId/device",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/device`,
									authRequired: true,
								},
								tanks: {
									path: "/app/vessel/onboard/:vesselId/tanks",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/tanks`,
									authRequired: true,
								},
								logs: {
									path: "/app/vessel/onboard/:vesselId/logs/:pageNumber",
									url: (vesselId: string, pageNumber: number = 1) =>
										`/app/vessel/onboard/${vesselId}/logs/${pageNumber}`,
									authRequired: true,
								},
								checklist: {
									path: "/app/vessel/onboard/:vesselId/checklist/:pageNumber",
									url: (vesselId: string, pageNumber: number = 1) =>
										`/app/vessel/onboard/${vesselId}/checklist/${pageNumber}`,
									authRequired: true,
								},
								reports: {
									path: "/app/vessel/onboard/:vesselId/reports/:pageNumber",
									url: (vesselId: string, pageNumber: number = 1) =>
										`/app/vessel/onboard/${vesselId}/reports/${pageNumber}`,
									authRequired: true,
								},
								seallogs: {
									path: "/app/vessel/onboard/:vesselId/seallogs/:pageNumber",
									url: (vesselId: string, pageNumber: number = 1) =>
										`/app/vessel/onboard/${vesselId}/seallogs/${pageNumber}`,
									authRequired: true,
								},
								marpol: {
									path: "/app/vessel/onboard/:vesselId/marpol/list/:pageNumber",
									url: (vesselId: string, pageNumber: number = 1) =>
										`/app/vessel/onboard/${vesselId}/marpol/list/${pageNumber}`,
									authRequired: true,
								},
								dashboard: {
									path: "/app/vessel/onboard/:vesselId/dashboard",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/dashboard`,
									authRequired: true,
								},
								info: {
									path: "/app/vessel/onboard/:vesselId/info",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/info`,
									authRequired: true,
								},
								ranges: {
									path: "/app/vessel/onboard/:vesselId/ranges",
									url: (vesselId: string) =>
										`/app/vessel/onboard/${vesselId}/ranges`,
									authRequired: true,
								},
							},
						},
					},
				},
				approval: {
					path: "/app/approval",
					url: () => "/app/approval",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/approval/list/:pageNumber",
							url: (pageNumber: number = 1) =>
								`/app/approval/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				marpol: {
					path: "/app/marpol",
					url: () => "/app/marpol",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/marpol/list/:pageNumber",
							url: (pageNumber: number = 1) => `/app/marpol/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				feedback: {
					path: "/app/feedback",
					url: () => "/app/feedback",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/feedback/list/:pageNumber",
							url: (pageNumber: number = 1) =>
								`/app/feedback/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
						view: {
							path: "/app/feedback/view/:feedbackId",
							url: (feedbackId: string) => `/app/feedback/view/${feedbackId}`,
							isOnSidebar: true,
							authRequired: true,
						},
						dashboard: {
							path: "/app/feedback/dashboard",
							url: () => `/app/feedback/dashboard`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				dashboard: {
					path: "/app/dashboard",
					url: () => "/app/dashboard",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/dashboard/list/",
							url: () => `/app/dashboard/list/`,
							isOnSidebar: true,
							authRequired: true,
						},
						view: {
							path: "/app/dashboard/view/:dashboardId",
							url: (dashboardId: string) =>
								`/app/dashboard/view/${dashboardId}`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				checklist: {
					path: "/app/checklist",
					url: () => "/app/checklist",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/checklist/list/:pageNumber",
							url: (pageNumber: number = 1) =>
								`/app/checklist/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
						saf01: {
							path: "/app/checklist/saf01",
							url: () => `/app/checklist/saf01`,
						},
					},
				},
				users: {
					path: "/app/users",
					url: () => "/app/users",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/users/list/:pageNumber",
							url: (pageNumber: number = 1) => `/app/users/list/${pageNumber}`,
							isOnSidebar: true,
							authRequired: true,
						},
						profile: {
							path: "/app/users/profile",
							url: () => `/app/users/profile`,
							isOnSidebar: true,
							authRequired: true,
						},
					},
				},
				reports: {
					path: "/app/reports",
					url: () => "/app/reports",
					isOnSidebar: true,
					authRequired: true,
					children: {
						categories: {
							path: "/app/reports/categories",
							url: () => `/app/reports/categories`,
							isOnSidebar: true,
							authRequired: true,
							children: {
								waste: {
									reportForWasteGarbage: {
										path: "/app/reports/categories/waste/garbage",
										url: () => "/app/reports/categories/waste/garbage",
										isOnSidebar: false,
										authRequired: true,
									},
									reportForWasteBilgeWater: {
										path: "/app/reports/categories/waste/bilge-water",
										url: () => "/app/reports/categories/waste/bilge-water",
										isOnSidebar: false,
										authRequired: true,
									},
									reportForWasteCargoSlop: {
										path: "/app/reports/categories/waste/cargo-slop",
										url: () => "/app/reports/categories/waste/cargo-slop",
										isOnSidebar: false,
										authRequired: true,
									},
									reportForWasteORS: {
										path: "/app/reports/categories/waste/ors",
										url: () => "/app/reports/categories/waste/ors",
										isOnSidebar: false,
										authRequired: true,
									},
								},
								other: {
									reportForOilSpillsInWater: {
										path: "/app/reports/categories/other/oil-spills-in-water",
										url: () =>
											"/app/reports/categories/other/oil-spills-in-water",
										isOnSidebar: false,
										authRequired: true,
									},
								},
								comprehensiveNoonReport: {
									comprehensiveNoonReport: {
										path: "/app/reports/categories/comprehensive-noon-report/comprehensive-noon-report",
										url: () =>
											"/app/reports/categories/comprehensive-noon-report/comprehensive-noon-report",
										isOnSidebar: false,
										authRequired: true,
									},
								},
							},
						},
					},
				},
				tools: {
					path: "/app/tools",
					url: () => "/app/tools",
					isOnSidebar: true,
					authRequired: true,
					children: {
						list: {
							path: "/app/tools/list",
							url: () => `/app/tools/list`,
							isOnSidebar: true,
							authRequired: true,
							children: {
								configPrint: {
									path: "/app/tools/list/config-print",
									url: () => `/app/tools/list/config-print`,
									isOnSidebar: false,
									authRequired: true,
								},
								configPrintActivityLog: {
									path: "/app/tools/list/activity-log",
									url: () => `/app/tools/list/activity-log`,
									isOnSidebar: false,
									authRequired: true,
								},
								trainingCertificate: {
									path: "/app/tools/list/training-certificate",
									url: () => `/app/tools/list/training-certificate`,
									isOnSidebar: false,
									authRequired: true,
									children: {
										approvals: {
											path: "/app/tools/list/training-certificate/approvals",
											url: () => `/app/tools/list/training-certificate/approvals`,
											isOnSidebar: false,
											authRequired: true,
										}
									}
								},
								groups: {
									path: "/app/tools/list/groups",
									url: () => `/app/tools/list/groups`,
									isOnSidebar: false,
									authRequired: true,
									
								}
							},
						},
					},
				},
				insights: {
					path: "/app/insights",
					url: () => "/app/insights",
					isOnSidebar: true,
					authRequired: true,
				},
			},
		},
		devCanvas: {
			path: "/dev-canvas",
			url: () => "/dev-canvas",
			isOnSidebar: false,
			authRequired: false,
		},
		ext: {
			path: "/ext",
			url: () => "/ext",
			children: {
				login: {
					path: "/ext/login",
					url: () => "/ext/login",
				},
				form: {
					path: "/ext/form",
					url: () => `/ext/form`,
					children: {
						tnk07: {
							path: "/ext/form/tnk07",
							url: (code: string = "tnk07") => `/ext/form/${code}`,
						},
						tec01: {
							path: "/ext/form/tec01",
							url: (code: string = "tec01") => `/ext/form/${code}`,
						},
					},
				},
			},
		},
	},
};

export default rootRoutes;
