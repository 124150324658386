import { Middleware } from "redux";
import toolActions, {
	EToolActions,
	TToolActions,
	toolJobActions,
} from "./actions";
import { IStore } from "redux/store";
import { apiActions } from "redux/_core/api";
import toolAPIs from "./api";
import {
	ICPActivityLog,
	IConfigInfo,
	IConfigListItem,
	IGroupInfo,
	IIndividualGroup,
	IPrintInfo,
	IPrintListItem,
	ITenant,
} from "./@types";
import { commonUtils } from "redux/_common";
import { IVessel, IVesselUser, TCount } from "../feedback/@types";
import toolSelectors from "./selectors";
import {
	getModelName,
	isJson,
	printListTitleExtractor,
	setConfigListBasedOnCategory,
	setPrintListBasedOnCategory,
} from "./utils";
import {
	CONFIG_TABS,
	CP_ACTIVITY_LOGS_PER_PAGE,
	PRINT_TABS,
} from "./constants";
import Papa from 'papaparse';
import { cloneDeep } from "lodash";
import { initialGroupDetails } from "./reducers";

const { apiRequest } = apiActions;

const toolMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: TToolActions) => {
		switch (action.type) {
			case EToolActions.TOOL_TENANT_SPECIFIC_ADD_TRAINING_USERS_FROM_CSV: {
				next(action);
				const job = toolJobActions.toolTenantSpecificAddTrainingUsersFromCSV;
				const { file, tenantId } = action.payload;

				Papa.parse(file, {
					download: true,
					header: true,
					skipEmptyLines: true,
					complete: function (answer) {
				
						const users: IVesselUser[] = [];
						answer.data.forEach((row: any) => {
							if (row.NAME && row.CID && row.RANK) {
								const user: IVesselUser = {
									name: row.NAME,
									staffId: row.CID,
									rank: row.RANK
								} as any;
								users.push(user);
							}
						});

						function chunkArray(array: any[], chunkSize: number) {
							const chunks = [];
							for (let i = 0; i < array.length; i += chunkSize) {
								chunks.push(array.slice(i, i + chunkSize));
							}
							return chunks;
						}

						const userChunks = chunkArray(users, 20);

						dispatch(
							job.active({
							  message: `Uploading ${userChunks.length} chunks of users...`,
							  notification: {},
							})
						  );

						const userChunksPromises = userChunks.map(async (chunk, index) => {
							return new Promise((resolve, reject) => {
								const currentChunk = chunk.map((user) => ({
									...user,
								}));
	
								dispatch(
									apiRequest<any>({
										...toolAPIs.tenantSpecificAddTrainingUsersFromCSV(
											currentChunk,
											tenantId
										),
										preExecute() {},
										postExecute: {
											onSuccess({ data }) {
												console.log({ data, index });
												resolve(data);
											},
											onError() {
												reject(index);
											},
											finally() {
												dispatch(job.idle({}));
											},
										},
									})
								);
							});
						});

						Promise.allSettled(userChunksPromises).then((results) => {
							let totalUpdated = 0;
							let totalInserted = 0;
							let totalErrors = 0;
							let failedBatches: any[] = [];
						  
							results.forEach((result, index) => {
							  if (result.status === "fulfilled") {
								const { updated, inserted, error } = (result as any).value;
								totalUpdated += updated.length;
								totalInserted += inserted.length;
								totalErrors += error.length;
							  } else if (result.status === "rejected") {
								failedBatches.push(index + 1);
							  }
							});
						  
							let message = `${totalUpdated} users updated, ${totalInserted} users inserted.`;
							
							if (totalErrors > 0) {
							  message += ` However, ${totalErrors} errors occurred.`;
							}
						  
							if (failedBatches.length > 0) {
							  message += ` The following batches failed: ${failedBatches.join(", ")}.`;
							}
						  
							dispatch(
							  totalErrors > 0 || failedBatches.length > 0
								? job.error({ message, notification: {} })
								: job.success({ message, notification: {} })
							);
						});						  
					},
					error: function (error) {
						dispatch(job.error({
							message: `Error parsing file: ${error.message}`,
							notification: {}
						}));
						console.error("Parsing error:", error);
					}
				});
				
				break;
			}
			case EToolActions.TOOL_TENANTS_LIST_LOAD: {
				next(action);
				const job = toolJobActions.toolTenantListLoad;

				dispatch(
					apiRequest<ITenant[]>({
						...toolAPIs.loadTenantsList(),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data.length) {
									dispatch(
										toolActions.document.toolTenantListSet(
											commonUtils.arrayToObject(data)
										)
									);
								} else {
									dispatch(
										toolActions.document.toolTenantListSet(
											commonUtils.arrayToObject([])
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to load tenants`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_VESSEL_SPECIFIC_USERS_LOAD: {
				next(action);
				const { vesselId, tenantId, isTrainingRequired } = action.payload;
				const job = toolJobActions.toolVesselSpecificUsersLoad;
				dispatch(
					apiActions.apiRequest<IVesselUser[]>({
						...toolAPIs.loadVesselSpecificUsers(tenantId, vesselId, isTrainingRequired),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								const vesselSpecificUsers = commonUtils.arrayToObject(data);
								dispatch(
									toolActions.document.toolVesselSpecificUsersSet(
										vesselSpecificUsers
									)
								);
								dispatch(job.success({}));
							},
							onError() {
								dispatch(
									job.error({
										message: "Error loading Users",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_USER_TRAINING_CERTIFICATE_APPROVE: {
				next(action);
				const { userId } = action.payload;
				const job = toolJobActions.toolUserTrainingCertificateApprove;
				dispatch(
					apiActions.apiRequest<any>({
						...toolAPIs.userTrainingCertificateApprove(userId),
						preExecute() {
							dispatch(
								job.active({
									message: "Approving training certificate...",
									notification: {},
								})
							);
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(toolActions.commands.toolVesselSpecificUsersLoad(undefined, undefined, true));
								dispatch(job.success({
									message: "Successfully approved training certificate.",
									notification: {},
								}));
							},
							onError() {
								dispatch(
									job.error({
										message: "Error approving training certificate.",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_VESSEL_SPECIFIC_USER_EDIT: {
				next(action);
				const { userId, date } = action.payload;
				const job = toolJobActions.toolVesselSpecificUsersLoad;
				const toolStore = toolSelectors._getStore(getState());
				const selectedTenantOption = toolSelectors.getSelectedTenantOption(toolStore);
				const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
				const approveCertificate = toolSelectors.getApproveCertificate(toolStore);

				dispatch(
					apiActions.apiRequest<IVesselUser[]>({
						...toolAPIs.editVesselSpecificUser(userId, date),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								const tenantId = selectedTenantOption?.value;
								const vesselId = selectedVesselOption?.value;
								if (approveCertificate) {
									dispatch(
										toolActions.commands.toolVesselSpecificUsersLoad(
											undefined,
											undefined,
											true
										)
									);
								} else if (tenantId) {
									dispatch(
										toolActions.commands.toolVesselSpecificUsersLoad(
											tenantId,
											vesselId,
										)
									);
								} else if (vesselId) {
									dispatch(
										toolActions.commands.toolVesselSpecificUsersLoad(
											vesselId
										)
									);
								}
								dispatch(job.success({}));
							},
							onError() {
								dispatch(
									job.error({
										message: "Error editing User training date",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_TENANT_SPECIFIC_VESSELS_LOAD: {
				next(action);
				const { tenantId, possibleLocation } = action.payload;
				const job = toolJobActions.toolTenantSpecificVesselsListLoad;

				dispatch(
					apiRequest<IVessel[]>({
						...toolAPIs.loadTenantSpecificVesselsList(tenantId),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data.length) {
									if (possibleLocation === "config-prints") {
										dispatch(
											toolActions.document.toolTenantSpecificVesselsSet(
												commonUtils.arrayToObject(data)
											)
										);
									} else if (possibleLocation === "config-copy") {
										dispatch(
											toolActions.document.toolConfigCopyModalTenantSpecificVesselsSet(
												commonUtils.arrayToObject(data)
											)
										);
									} else if (possibleLocation === "print-copy") {
										dispatch(
											toolActions.document.toolPrintCopyModalTenantSpecificVesselsSet(
												commonUtils.arrayToObject(data)
											)
										);
									}
								} else {
									dispatch(
										toolActions.document.toolTenantSpecificVesselsSet(
											commonUtils.arrayToObject([])
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to load vessels`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_CONFIG_LIST_LOAD: {
				next(action);
				const { configType, vesselId } = action.payload;
				const authStore = getState().app.auth;
				const job = toolJobActions.toolConfigListLoad;
				const category = configType;
				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore?.authToken ?? undefined;

				dispatch(
					apiRequest<IConfigListItem[]>({
						...toolAPIs.loadConfigsListForType(
							category,
							userId,
							token,
							vesselId
						),
						preExecute() {
							dispatch(
								job.active({
									message: "Loading config...",
								})
							);
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data.length) {
									if (configType === "logs") {
										dispatch(
											toolActions.document.toolConfigLogsListSet(
												commonUtils.arrayToObject(
													data.map((val) => ({ ...val, id: val._id }))
												)
											)
										);
									} else if (configType === "checklists") {
										dispatch(
											toolActions.document.toolConfigChecklistsListSet(
												commonUtils.arrayToObject(
													data.map((val) => ({ ...val, id: val._id }))
												)
											)
										);
									} else if (configType === "permits") {
										dispatch(
											toolActions.document.toolConfigPermitsListSet(
												commonUtils.arrayToObject(
													data.map((val) => ({ ...val, id: val._id }))
												)
											)
										);
									}
								}
							},
							onError() {
								setConfigListBasedOnCategory(
									category as any,
									commonUtils.arrayToObject([]),
									dispatch
								);

								dispatch(
									job.error({
										notification: {},
										message: `Failed to load config list for ${configType}`,
									})
								);
							},
							finally() {
								dispatch(
									job.idle({ notification: undefined, message: undefined })
								);
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_CONFIG_LOAD: {
				next(action);
				const { configId } = action.payload;
				const authStore = getState().app.auth;
				const job = toolJobActions.toolConfigLoad;

				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore?.authToken ?? undefined;

				dispatch(
					apiRequest<IConfigInfo>({
						...toolAPIs.loadConfigInfo(configId, token ?? "", userId ?? ""),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data, status }) {
								if (status === 200 && data?._id) {
									dispatch(toolActions.document.toolConfigInfoSet(data));
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to load config info`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_EMPTY_CONFIG_LIST_FOR_ALL_CATEGORY: {
				next(action);
				dispatch(
					toolActions.document.toolConfigLogsListSet(
						commonUtils.arrayToObject([])
					)
				);
				dispatch(
					toolActions.document.toolConfigChecklistsListSet(
						commonUtils.arrayToObject([])
					)
				);
				dispatch(
					toolActions.document.toolConfigPermitsListSet(
						commonUtils.arrayToObject([])
					)
				);

				break;
			}
			case EToolActions.TOOL_CONFIG_CREATE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolConfigCreate;
				const category = toolSelectors.getActiveTabForConfig(toolStore);
				const selectedTenant =
					toolSelectors.getSelectedTenantOption(toolStore)?.value;
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				const { config, changeLog } = action.payload;
				const isjson = isJson(config);
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				if (isjson) {
					const modelName = getModelName(category);
					const configObj = JSON.parse(config);
					configObj["modelName"] = modelName;
					configObj["tenantId"] = selectedTenant;
					configObj["vesselId"] = selectedVessel;
					configObj["changeLog"] = changeLog;
					if (category === CONFIG_TABS.TAB_CHECKLISTS)
						configObj["directoryListingType"] = "CHECKLIST";
					if (category === CONFIG_TABS.TAB_PERMITS)
						configObj["directoryListingType"] = "PERMIT";

					dispatch(
						apiRequest<any>({
							...toolAPIs.configCreate(configObj, token, userId),
							preExecute() {
								dispatch(job.active({}));
							},
							postExecute: {
								onSuccess({ data }) {
									if (data?.ok) {
										dispatch(toolActions.document.configAddModalToggle());
										dispatch(
											toolActions.commands.toolConfigListLoad(
												category,
												selectedVessel
											)
										);
										dispatch(
											job.success({
												notification: {},
												message: "Successfully created the config.",
											})
										);
									}
								},
								onError(err) {
									dispatch(
										toolActions.document.toolConfigErrorMessageSet(
											err?.response?.data?.message
										)
									);
								},
								finally() {
									dispatch(job.idle({}));
								},
							},
						})
					);
				} else {
					dispatch(
						toolActions.document.toolConfigErrorMessageSet(
							"Provide a valid json"
						)
					);
				}
				break;
			}
			case EToolActions.TOOL_CONFIG_UPDATE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolConfigUpdate;
				const category = toolSelectors.getActiveTabForConfig(toolStore);
				const selectedTenant =
					toolSelectors.getSelectedTenantOption(toolStore)?.value;
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				const { config, changeLog } = action.payload;
				const isjson = isJson(config);
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				if (isjson) {
					const modelName = getModelName(category);
					const configObj = JSON.parse(config);
					configObj["modelName"] = modelName;
					configObj["tenantId"] = selectedTenant;
					configObj["vesselId"] = selectedVessel;
					configObj["changeLog"] = changeLog;
					dispatch(
						apiRequest<any>({
							...toolAPIs.configUpdate(configObj, token, userId),
							preExecute() {
								dispatch(
									job.active({
										notification: {},
										message: "Updating the config...",
									})
								);
							},
							postExecute: {
								onSuccess({ data }) {
									if (data?.ok) {
										dispatch(toolActions.document.updateModalToggle());
										dispatch(
											toolActions.commands.toolConfigListLoad(
												category,
												selectedVessel
											)
										);
										dispatch(
											job.success({
												notification: {},
												message: "Updated the config.",
											})
										);
									}
								},
								onError(err) {
									dispatch(
										toolActions.document.toolConfigErrorMessageSet(
											err?.response?.data?.message
										)
									);
								},
								finally() {
									dispatch(job.idle({}));
								},
							},
						})
					);
				}
				break;
			}
			case EToolActions.TOOL_CONFIG_COPY: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolConfigCopy;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";
				const category = toolSelectors.getActiveTabForConfig(toolStore);
				const modelName = getModelName(category);
				const fromVesselOption =
					toolSelectors.getSelectedVesselOption(toolStore);
				const toTenantOption =
					toolSelectors.getConfigCopySelectedTenantOption(toolStore);
				const toVesselOptions =
					toolSelectors.getConfigCopySelectedVesselOptions(toolStore);
				const { itemsToBeCopied } = action.payload;

				// Validation
				let errorMessageValidator = "";
				if (!fromVesselOption?.value)
					errorMessageValidator = "Please select the from vessel.";
				else if (!toTenantOption?.value)
					errorMessageValidator =
						"Please select the tenant for the vessel to which you want to copy.";
				else if (!toVesselOptions?.length)
					errorMessageValidator =
						"Please select atleast one vessel to which you want to copy";

				if (errorMessageValidator) {
					dispatch(
						toolActions.document.toolConfigErrorMessageSet(
							errorMessageValidator
						)
					);
					return;
				}

				dispatch(
					apiRequest<{ message: string }>({
						...toolAPIs.configCopy(
							{
								modelName,
								fromVesselId: fromVesselOption?.value ?? "",
								items: itemsToBeCopied.map((item) => item.type),
								toTenantId: toTenantOption?.value ?? "",
								toVesselIds: toVesselOptions
									? toVesselOptions?.map((vessel) => vessel.value) ?? []
									: [],
							},
							"copy",
							token,
							userId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (data?.message === "Success") {
									dispatch(toolActions.document.toolConfigItemsSelect([]));
									dispatch(toolActions.document.copyModalToggle());
								}
							},
							onError(err) {
								dispatch(
									toolActions.document.toolConfigErrorMessageSet(
										err?.response?.data?.message
									)
								);
								dispatch(
									job.error({
										message: `Failed to copy config`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_CONFIG_DELETE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const { configId } = action.payload;
				const job = toolJobActions.toolConfigDelete;

				const category = toolSelectors.getActiveTabForConfig(toolStore);
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				let originalConfigList: any;
				if (category === CONFIG_TABS.TAB_LOGS) {
					originalConfigList = toolSelectors.getConfigLogsList(toolStore);
				} else if (category === CONFIG_TABS.TAB_CHECKLISTS) {
					originalConfigList = toolSelectors.getConfigChecklistsList(toolStore);
				} else {
					originalConfigList = toolSelectors.getConfigPermitsList(toolStore);
				}

				dispatch(
					apiRequest<{ message: string }>({
						...toolAPIs.configDelete(configId, token, userId),
						preExecute() {
							dispatch(job.active({}));
							// setting the local state
							let updatedConfigList: any;

							configId in originalConfigList?.byIds &&
								delete originalConfigList.byIds[configId];
							updatedConfigList = {
								ids: originalConfigList?.ids?.filter(
									(itemId: string) => itemId !== configId
								),
								byIds: originalConfigList?.byIds,
							};

							setConfigListBasedOnCategory(
								category,
								updatedConfigList,
								dispatch
							);
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data?.message) {
									dispatch(
										toolActions.commands.toolConfigListLoad(
											category,
											selectedVessel
										)
									);
									dispatch(toolActions.document.toolConfigIdOnActionSelect(""));
								}
							},
							onError() {
								setConfigListBasedOnCategory(
									category,
									originalConfigList,
									dispatch
								);
								dispatch(
									job.error({
										message: `Failed to delete config`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_CONFIG_SEARCH_CURRENT_PAGE_SET: {
				next(action);
				const toolStore = toolSelectors._getStore(getState());
				const currentActiveTab = toolSelectors.getActiveTabForConfig(toolStore);

				if (currentActiveTab === CONFIG_TABS.TAB_LOGS) {
					dispatch(
						toolActions.document.toolConfigLogPaginationCurrentPageSet(1)
					);
				} else if (currentActiveTab === CONFIG_TABS.TAB_CHECKLISTS) {
					dispatch(
						toolActions.document.toolConfigChecklistPaginationCurrentPageSet(1)
					);
				} else {
					dispatch(
						toolActions.document.toolConfigPermitPaginationCurrentPageSet(1)
					);
				}

				break;
			}

			case EToolActions.TOOL_PRINT_LIST_LOAD: {
				next(action);
				const { printType, vesselId } = action.payload;
				const authStore = getState().app.auth;
				const job = toolJobActions.toolPrintListLoad;
				const category = printType;
				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore?.authToken ?? undefined;

				dispatch(
					apiRequest<IPrintListItem[]>({
						// TODO: need to replace with print specific api
						...toolAPIs.loadPrintsListForType(
							category,
							userId,
							token,
							vesselId
						),
						preExecute() {
							dispatch(
								job.active({
									message: "Loading print...",
								})
							);
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data.length) {
									if (printType === "logs") {
										dispatch(
											toolActions.document.toolPrintLogsListSet(
												commonUtils.arrayToObject(
													data.map((val) => {
														const { code, typeOfLogs, title } = val;
														return {
															...val,
															id: val._id,
															title:
																title ??
																printListTitleExtractor(code, typeOfLogs),
														};
													})
												)
											)
										);
									} else if (printType === "checklists") {
										dispatch(
											toolActions.document.toolPrintChecklistsListSet(
												commonUtils.arrayToObject(
													data.map((val) => ({
														...val,
														id: val._id,
														title:
															val?.title ??
															printListTitleExtractor(val.code, val.typeOfLogs),
													}))
												)
											)
										);
									} else if (printType === "permits") {
										dispatch(
											toolActions.document.toolPrintPermitsListSet(
												commonUtils.arrayToObject(
													data.map((val) => ({
														...val,
														id: val._id,
														title:
															val?.title ??
															printListTitleExtractor(val.code, val.typeOfLogs),
													}))
												)
											)
										);
									}
								}
							},
							onError() {
								setPrintListBasedOnCategory(
									category as any,
									commonUtils.arrayToObject([]),
									dispatch
								);

								dispatch(
									job.error({
										notification: {},
										message: `Failed to load print list for ${printType}`,
									})
								);
							},
							finally() {
								dispatch(
									job.idle({ notification: undefined, message: undefined })
								);
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_PRINT_LOAD: {
				next(action);
				const { printId } = action.payload;
				const authStore = getState().app.auth;
				const job = toolJobActions.toolPrintLoad;

				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore?.authToken ?? undefined;

				dispatch(
					apiRequest<IPrintInfo>({
						// TODO: need to replace with print specific commands
						...toolAPIs.loadPrintInfo(printId, token ?? "", userId ?? ""),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data, status }) {
								if (status === 200 && data?._id) {
									dispatch(toolActions.document.toolPrintInfoSet(data));
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to load print info`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_EMPTY_PRINT_LIST_FOR_ALL_CATEGORY: {
				next(action);
				dispatch(
					toolActions.document.toolPrintLogsListSet(
						commonUtils.arrayToObject([])
					)
				);
				dispatch(
					toolActions.document.toolPrintChecklistsListSet(
						commonUtils.arrayToObject([])
					)
				);
				dispatch(
					toolActions.document.toolPrintPermitsListSet(
						commonUtils.arrayToObject([])
					)
				);

				break;
			}
			case EToolActions.TOOL_PRINT_CREATE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolPrintCreate;
				const category = toolSelectors.getActiveTabForPrint(toolStore);
				const selectedTenant =
					toolSelectors.getSelectedTenantOption(toolStore)?.value;
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				// INFO: print is equivalent to config objec
				const { print, changeLog } = action.payload;
				const isjson = isJson(print);
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				if (isjson) {
					// TODO: need to replace with print specific things accordingly
					const modelName = "printTemplates";
					const printObj = JSON.parse(print);
					printObj["modelName"] = modelName;
					printObj["tenantId"] = selectedTenant;
					printObj["vesselId"] = selectedVessel;
					printObj["changeLog"] = changeLog;

					dispatch(
						apiRequest<any>({
							// TODO: need to replace with print specific commands
							...toolAPIs.printCreate(printObj, token, userId),
							preExecute() {
								dispatch(job.active({}));
							},
							postExecute: {
								onSuccess({ data }) {
									if (data?.ok) {
										dispatch(toolActions.document.printAddModalToggle());
										dispatch(
											toolActions.commands.toolPrintListLoad(
												category,
												selectedVessel
											)
										);
										dispatch(
											job.success({
												notification: {},
												message: "Successfully created the print.",
											})
										);
									}
								},
								onError(err) {
									dispatch(
										toolActions.document.toolPrintErrorMessageSet(
											err?.response?.data?.message
										)
									);
								},
								finally() {
									dispatch(job.idle({}));
								},
							},
						})
					);
				} else {
					dispatch(
						toolActions.document.toolPrintErrorMessageSet(
							"Provide a valid json"
						)
					);
				}
				break;
			}
			case EToolActions.TOOL_PRINT_UPDATE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolPrintUpdate;
				const category = toolSelectors.getActiveTabForPrint(toolStore);
				const selectedTenant =
					toolSelectors.getSelectedTenantOption(toolStore)?.value;
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				const { print, changeLog, newFileRequired = false } = action.payload;
				const isjson = isJson(print);
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";
				// TODO: need to replace with print specific commands
				if (isjson) {
					const modelName = "printTemplates";
					const printObj = JSON.parse(print);
					printObj["modelName"] = modelName;
					printObj["tenantId"] = selectedTenant;
					printObj["vesselId"] = selectedVessel;
					printObj["changeLog"] = changeLog;
					printObj["newFileRequired"] = newFileRequired;
					dispatch(
						apiRequest<any>({
							...toolAPIs.printUpdate(printObj, token, userId),
							preExecute() {
								dispatch(
									job.active({
										notification: {},
										message: "Updating the print...",
									})
								);
							},
							postExecute: {
								onSuccess({ data }) {
									if (data?.ok) {
										dispatch(toolActions.document.printUpdateModalToggle());
										dispatch(
											toolActions.commands.toolPrintListLoad(
												category,
												selectedVessel
											)
										);
										dispatch(
											job.success({
												notification: {},
												message: "Updated the print.",
											})
										);
									}
								},
								onError(err) {
									dispatch(
										toolActions.document.toolPrintErrorMessageSet(
											err?.response?.data?.message
										)
									);
								},
								finally() {
									dispatch(job.idle({}));
								},
							},
						})
					);
				}
				break;
			}
			case EToolActions.TOOL_PRINT_COPY: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolPrintCopy;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				const modelName = "printTemplates";
				const fromVesselOption =
					toolSelectors.getSelectedVesselOption(toolStore);
				const toTenantOption =
					toolSelectors.getPrintCopySelectedTenantOption(toolStore);
				const toVesselOptions =
					toolSelectors.getPrintCopySelectedVesselOptions(toolStore);
				const { itemsToBeCopied } = action.payload;

				// Validation
				let errorMessageValidator = "";
				if (!fromVesselOption?.value)
					errorMessageValidator = "Please select the from vessel.";
				else if (!toTenantOption?.value)
					errorMessageValidator =
						"Please select the tenant for the vessel to which you want to copy.";
				else if (!toVesselOptions?.length)
					errorMessageValidator =
						"Please select atleast one vessel to which you want to copy";

				if (errorMessageValidator) {
					dispatch(
						toolActions.document.toolPrintErrorMessageSet(errorMessageValidator)
					);
					return;
				}

				const items = itemsToBeCopied.map((item) => {
					return {
						code: item.code,
						version: item.version,
					};
				});

				const activeTabForPrint = toolSelectors.getActiveTabForPrint(toolStore);

				dispatch(
					apiRequest<{ message: string }>({
						// TODO: need to replace with print specific commands
						...toolAPIs.printCopy(
							{
								modelName,
								fromVesselId: fromVesselOption?.value ?? "",
								items,
								toTenantId: toTenantOption?.value ?? "",
								toVesselIds: toVesselOptions
									? toVesselOptions?.map((vessel) => vessel.value) ?? []
									: [],
								logCategory: activeTabForPrint,
							},
							"copy",
							token,
							userId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (data?.message === "Success") {
									dispatch(toolActions.document.toolPrintItemsSelect([]));
									dispatch(toolActions.document.printCopyModalToggle());
								}
							},
							onError(err) {
								dispatch(
									toolActions.document.toolPrintErrorMessageSet(
										err?.response?.data?.message
									)
								);
								dispatch(
									job.error({
										message: `Failed to copy print`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_PRINT_DELETE: {
				next(action);
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const { printId } = action.payload;
				const job = toolJobActions.toolPrintDelete;

				const category = toolSelectors.getActiveTabForPrint(toolStore);
				const selectedVessel =
					toolSelectors.getSelectedVesselOption(toolStore)?.value;

				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				let originalPrintList: any;
				if (category === PRINT_TABS.TAB_LOGS) {
					originalPrintList = toolSelectors.getPrintLogsList(toolStore);
				} else if (category === PRINT_TABS.TAB_CHECKLISTS) {
					originalPrintList = toolSelectors.getPrintChecklistsList(toolStore);
				} else {
					originalPrintList = toolSelectors.getPrintPermitsList(toolStore);
				}

				dispatch(
					apiRequest<{ message: string }>({
						...toolAPIs.printDelete(printId, token, userId),
						preExecute() {
							dispatch(job.active({}));
							// setting the local state
							let updatedPrintList: any;

							printId in originalPrintList?.byIds &&
								delete originalPrintList.byIds[printId];
							updatedPrintList = {
								ids: originalPrintList?.ids?.filter(
									(itemId: string) => itemId !== printId
								),
								byIds: originalPrintList?.byIds,
							};

							setPrintListBasedOnCategory(category, updatedPrintList, dispatch);
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data?.message) {
									dispatch(
										toolActions.commands.toolPrintListLoad(
											category,
											selectedVessel
										)
									);
									dispatch(toolActions.document.toolPrintIdOnActionSelect(""));
								}
							},
							onError() {
								setPrintListBasedOnCategory(
									category,
									originalPrintList,
									dispatch
								);
								dispatch(
									job.error({
										message: `Failed to delete print`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EToolActions.TOOL_PRINT_SEARCH_CURRENT_PAGE_SET: {
				next(action);
				const toolStore = toolSelectors._getStore(getState());
				const currentActiveTab = toolSelectors.getActiveTabForPrint(toolStore);

				if (currentActiveTab === PRINT_TABS.TAB_LOGS) {
					dispatch(
						toolActions.document.toolPrintLogPaginationCurrentPageSet(1)
					);
				} else if (currentActiveTab === PRINT_TABS.TAB_CHECKLISTS) {
					dispatch(
						toolActions.document.toolPrintChecklistPaginationCurrentPageSet(1)
					);
				} else {
					dispatch(
						toolActions.document.toolPrintPermitPaginationCurrentPageSet(1)
					);
				}

				break;
			}

			case EToolActions.TOOL_CONFIG_PRINT_ACTIVITY_LOG_LOAD: {
				next(action);
				const { isCardView } = action.payload;
				const authStore = getState().app.auth;
				const toolStore = toolSelectors._getStore(getState());
				const job = toolJobActions.toolCPActivityLog;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";
				const itemsPerPage = isCardView ? 100 : CP_ACTIVITY_LOGS_PER_PAGE;
				const pageNumber = isCardView
					? toolStore.toolCPActivityLogCard._pagination.currentPage
					: toolStore.toolCPActivityLog._pagination.currentPage;
				dispatch(
					apiRequest<ICPActivityLog[]>({
						// TODO: 200 to be replaced with itemsPerPage
						...toolAPIs.toolCPActivityLogList(
							token,
							userId,
							itemsPerPage,
							pageNumber
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								// TODO: possibly just check whether we are getting array response or not, accordingly call just one dispatch to set.
								if (data && data?.length) {
									if (isCardView) {
										dispatch(
											toolActions.document.toolCPCardActivityLogListSet(data)
										);
										dispatch(
											toolActions.document.toolCPCardActivityLogPaginationCurrentPageSet(
												pageNumber + 1
											)
										);
									} else {
										dispatch(
											toolActions.document.toolCPActivityLogListSet(
												commonUtils.arrayToObject(
													data.map((activity) => ({
														...activity,
														id: activity?.id,
													}))
												)
											)
										);
									}
								} else {
									dispatch(
										toolActions.document.toolCPActivityLogListSet(
											commonUtils.arrayToObject([])
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to get Config & Prints activity log.`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			case EToolActions.TOOL_LIST_COUNT_LOAD: {
				next(action);
				const job = toolJobActions.toolListCountLoad;
				const { modelName, typeOfTool } = action.payload;
				dispatch(
					apiRequest<TCount>({
						...toolAPIs.getListCountByModelName(modelName),
						preExecute() {},
						postExecute: {
							onSuccess({ data }) {
								if (typeof data?.count === "number") {
									if (typeOfTool === "cp-activity-log") {
										dispatch(
											toolActions.document.toolCPActivityLogPaginationTotalItemsSet(
												data.count
											)
										);
									}
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load count",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}


			case EToolActions.TOOL_GROUPS_LIST_LOAD: {
				next(action);
				const { tenantId } = action.payload;
				const job = toolJobActions.toolGroupsListLoad;

				dispatch(
					apiRequest<IGroupInfo[]>({
						...toolAPIs.loadGroupsList(tenantId),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (data && data.length) {
									data = data?.map((group) => ({ ...group, id: group._id }));
									dispatch(
										toolActions.document.toolGroupListSet(
											commonUtils.arrayToObject(data)
										)
									);
									dispatch(
										toolActions.document.toolGroupsPaginationTotalItemsSet(
											data.length
										)
									);
								} else {
									dispatch(
										toolActions.document.toolGroupListSet(
											commonUtils.arrayToObject([])
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to load groups`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}

			case EToolActions.TOOL_GROUPS_GROUP_CREATE: {
				next(action);

				const job = toolJobActions.toolGroupsGroupCreate;
				const toolStore = toolSelectors._getStore(getState());
				const groupDetails = toolSelectors.getFormGroupDetails(toolStore);

				dispatch(
					apiRequest<any>({
						...toolAPIs.createGroup(groupDetails),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								
								if (data?.ok) {
									dispatch(toolActions.commands.toolGroupsListLoad());
									dispatch(
										toolActions.document.toolGroupsGroupDetailsSet(
											cloneDeep(initialGroupDetails)
										)
									);
									dispatch(
										toolActions.document.groupModalToggle(
											false
										)
									)
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to create a group`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}

			case EToolActions.TOOL_GROUPS_GROUP_UPDATE: {
				next(action);

				const job = toolJobActions.toolGroupsGroupUpdate;
				const toolStore = toolSelectors._getStore(getState());
				const groupDetails = toolSelectors.getFormGroupDetails(toolStore);

				const updatedGroupDetails:Partial<IIndividualGroup & {groupId:string}>   = cloneDeep(groupDetails);
				delete updatedGroupDetails.tenantId

				const idOfGroupToBeUpdated = toolSelectors.getIdOfTheGroupToBeUpdated(toolStore);
				updatedGroupDetails.groupId = idOfGroupToBeUpdated;


				dispatch(
					apiRequest<any>({
						...toolAPIs.updateGroup(updatedGroupDetails),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								
								if (data?.ok) {
									dispatch(toolActions.commands.toolGroupsListLoad());
									dispatch(
										toolActions.document.toolGroupsGroupDetailsSet(
											cloneDeep(initialGroupDetails)
										)
									);
									dispatch(
										toolActions.document.groupModalToggle(
											false
										)
									)
								}
							},
							onError() {
								dispatch(
									job.error({
										message: `Failed to update a group`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}


			default:
				next(action);
		}
	};

export default toolMiddleware;
