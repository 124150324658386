import Button from "@set-product/core/button/Button";
import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Select from "react-select";
import SVGCross from "components/_common/icons/tool/SVGCross";
import SVGItem from "components/_common/icons/tool/SVGItem";
import SVGCancel from "components/_common/icons/tool/SVGCancel";
import SVGTo from "components/_common/icons/tool/SVGTo";
import classNames from "classnames";
import clsx from "clsx";
import {
	clearIndicatorStyles,
	controlStyles,
	dropdownIndicatorStyles,
	menuStyles,
	multiValueStyles,
	optionStyles,
	placeholderStyles,
} from "redux/app/feedback/utils";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import {
	getTenantsOptionList,
	getVesselsOptionList,
	printListTitleExtractor,
} from "redux/app/tool/utils";
import { IOption } from "redux/app/feedback/@types";
import { PRINT_TABS } from "redux/app/tool/constants";
import { IPrintListItem } from "redux/app/tool/@types";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";

type OwnProps = {};
type PrintCopyProps = PropsFromRedux & OwnProps;
function PrintCopy(props: PrintCopyProps) {
	const {
		tenants,
		vessels,
		selectedTenantOption,
		selectedVesselOptions,
		jobToLoadVesselSpecificToTenant,
		currentVesselDetails,
		printIdToBeCopied,
		printList,
		selectedItems,
		errorMessage,
		jobToCopyPrint,
		selectedGroupOption,
		groupList,
	} = props;
	const tenantOptions = useMemo(
		() => getTenantsOptionList(tenants),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tenants.ids]
	);
	const vesselsOptions = useMemo(
		() => getVesselsOptionList(vessels),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[vessels.ids]
	);

	const groupOptions = groupList?.ids?.map((groupId) => {
		const group = groupList.byIds[groupId];
		return {
			label: group?.groupName,
			value: groupId,
		};
	});

	useEffect(() => {
		const tenantId = selectedTenantOption?.value;
		if (tenantId) {
			props.loadTenantSpecificVesselsList(tenantId);
			props.loadGroupsList(selectedTenantOption?.value);
		}
		props.setVessels(undefined);
		props.setGroupOption(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value]);

	useEffect(() => {
		return () => {
			props.setTenant(undefined);
			props.setVessels(undefined);
			props.setActionPrintId("");
			// props.setSelectedItems([])
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const itemsToBeCopied = useMemo(() => {
		let itemsToBeCopied = [];
		if (selectedItems.length) {
			selectedItems.forEach((id) => {
				if (id in printList.byIds) itemsToBeCopied.push(printList.byIds[id]);
			});
		} else {
			if (printIdToBeCopied in printList.byIds) {
				const config = printList.byIds[printIdToBeCopied];
				itemsToBeCopied.push(config);
			}
		}
		return itemsToBeCopied;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems, printList.ids, printIdToBeCopied]);

	return (
		<>
			<div className="h-full max-h-[calc(90vh)]  overflow-y-auto bg-white rounded-lg font-redhat-text">
				<div className="flex justify-between items-center py-2.5 px-4 border-[#C8C7C7] border-b">
					<div>
						<h2 className="text-2xl font-medium mb-0">Copy </h2>
						<p className="m-0 text-sm">from {currentVesselDetails?.label}</p>
						{errorMessage && (
							<span className="text-xs text-red-500">{errorMessage}</span>
						)}
					</div>
					<div
						className="cursor-pointer"
						onClick={() => props.toggleCopyModal()}
					>
						<SVGCross />
					</div>
				</div>
				<div className="flex ">
					<div className="w-1/2 border-r border-[#C8C7C7]">
						<div className="border-b border-[#C8C7C7] flex items-center py-2 px-4 h-10">
							<SVGItem />
							<h4 className="text-[#718096] text-sm ml-2">Items</h4>
						</div>
						<div className="min-h-[250px]">
							{itemsToBeCopied.map((item) => {
								const { title, version, _id, code, typeOfLogs } = item;
								return (
									<div
										key={title}
										className="border-[#D4D1D1] rounded-md m-2 p-2 bg-[#F7F7F7] flex justify-between items-center"
									>
										<div>
											<p className="text-[12px] m-0">
												{title ?? printListTitleExtractor(code, typeOfLogs)}
											</p>
											<p className="text-xs m-0">{version}</p>
										</div>
										{itemsToBeCopied.length > 1 && (
											<div
												onClick={() => {
													props.setSelectedItems(
														selectedItems.filter((itemId) => itemId !== _id)
													);
													props.setErrorMessage("");
												}}
												className="w-6 cursor-pointer "
											>
												<SVGCancel />
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
					<div className="w-1/2 ">
					<div className="border-b border-[#C8C7C7] flex justify-between items-center py-2 px-4 h-10">
							<div className="flex items-center">
								<SVGTo />
								<h4 className="text-[#718096] text-sm ml-2">To</h4>
							</div>
							{selectedVesselOptions?.length ? (
								<p className="text-sm text-gray-600">
									{" "}
									Selected vessels :{" "}
									<span className="font-medium">
										{selectedVesselOptions?.length}
									</span>{" "}
								</p>
							) : null}
						</div>
						<div className="m-2">
							<Select
								id="tenants"
								isClearable
								isSearchable
								key={"tenants" + selectedTenantOption?.value}
								placeholder={"Search tenants"}
								isDisabled={false}
								unstyled
								value={selectedTenantOption}
								options={tenantOptions}
								onChange={(newValue, actionMeta) => {
									props.setErrorMessage("");
									props.setTenant(newValue ?? undefined);
								}}
								className={classNames("w-full text-sm", {
									"cursor-not-allowed": false,
								})}
								menuPosition="fixed"
								classNames={{
									option: ({ isFocused, isSelected }) =>
										clsx(
											isFocused && optionStyles.focus,
											isSelected && optionStyles.selected,
											optionStyles.base
										),

									dropdownIndicator: () => dropdownIndicatorStyles,
									menu: () => menuStyles,
									clearIndicator: () => clearIndicatorStyles,
									placeholder: () => placeholderStyles,
									control: ({ isFocused, isDisabled }) =>
										clsx(
											isFocused ? controlStyles.focus : controlStyles.nonFocus,
											isDisabled ? controlStyles.disabled : controlStyles.base
										),

									valueContainer: ({ isDisabled }) =>
										clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
								}}
							/>
						</div>
						<div className="m-2">
							<Select
								id="vessels"
								isMulti
								isClearable
								isSearchable
								key={
									"vessels" +
									selectedVesselOptions?.map((vessel) => vessel?.value).join("")
								}
								placeholder={"Search vessels"}
								isDisabled={!selectedTenantOption?.value}
								unstyled
								value={selectedVesselOptions}
								options={vesselsOptions.filter(
									(vesselOption) =>
										vesselOption?.value !== currentVesselDetails?.value
								)}
								isLoading={jobToLoadVesselSpecificToTenant.isLoading}
								onChange={(newValue, actionMeta) => {
									props.setErrorMessage("");
									props.setVessels(
										newValue.map((vessel) => ({
											label: vessel.label,
											value: vessel.value,
										}))
									);
								}}
								className={classNames("w-full text-sm", {
									"cursor-not-allowed": false,
								})}
								menuPosition="fixed"
								classNames={{
									option: ({ isFocused, isSelected }) =>
										clsx(
											isFocused && optionStyles.focus,
											isSelected && optionStyles.selected,
											optionStyles.base
										),
									multiValue: () => multiValueStyles,
									dropdownIndicator: () => dropdownIndicatorStyles,
									menu: () => menuStyles,
									clearIndicator: () => clearIndicatorStyles,
									placeholder: () => placeholderStyles,
									control: ({ isFocused, isDisabled }) =>
										clsx(
											isFocused ? controlStyles.focus : controlStyles.nonFocus,
											isDisabled ? controlStyles.disabled : controlStyles.base
										),

									valueContainer: ({ isDisabled }) =>
										clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
								}}
							/>
						</div>
						<div className="m-2 mt-6">
							<h4 className="text-sm">Get vessels based on Group</h4>
							<div className="mt-2">
								<Select
									id="groups"
									isClearable
									isSearchable
									key={"groups" + selectedGroupOption?.value}
									placeholder={"Search Groups"}
									isDisabled={!selectedTenantOption?.value}
									unstyled
									value={selectedGroupOption}
									options={groupOptions}
									onChange={(newValue, actionMeta) => {
										props.setErrorMessage("");
										const groupId = newValue?.value;
										if (groupId) {
											props.setGroupOption({
												label: groupList.byIds[groupId].groupName,
												value: groupId,
											});
											const groupVessels = groupList.byIds[
												groupId
											]?.vessels?.map((ves) => ({
												label: ves.vesselName,
												value: ves.vesselId,
											}));
											props.setVessels(groupVessels);
										} else {
											props.setVessels([]);
										}

										// props.setTenant(newValue ?? undefined);
									}}
									className={classNames("w-full text-sm", {
										"cursor-not-allowed": false,
									})}
									menuPosition="fixed"
									classNames={{
										option: ({ isFocused, isSelected }) =>
											clsx(
												isFocused && optionStyles.focus,
												isSelected && optionStyles.selected,
												optionStyles.base
											),

										dropdownIndicator: () => dropdownIndicatorStyles,
										menu: () => menuStyles,
										clearIndicator: () => clearIndicatorStyles,
										placeholder: () => placeholderStyles,
										control: ({ isFocused, isDisabled }) =>
											clsx(
												isFocused
													? controlStyles.focus
													: controlStyles.nonFocus,
												isDisabled ? controlStyles.disabled : controlStyles.base
											),

										valueContainer: ({ isDisabled }) =>
											clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
									}}
								/>
							</div>
							<div
								onClick={() => {
									props.toggleCopyModal();
									props.goTo(
										"#groups",
										rootRoutes.children.app.children.tools.children.list.children.groups.url()
									);
								}}
								className="flex justify-end text-sm text-red-500 underline cursor-pointer"
							>
								{!groupOptions?.length && selectedTenantOption?.value
									? "Create group"
									: "Manage groups"}
							</div>
						</div>
					</div>
				</div>
				<div className="border-[#C8C7C7] border-t  p-2">
					<Button
						className={classNames(" w-full bg-blue-700 disabled:bg-gray-300 ", {
							"cursor-not-allowed": jobToCopyPrint.isLoading,
						})}
						onClick={() => {
							props.performCopyOperation(itemsToBeCopied);
						}}
						disabled={false}
						loading={jobToCopyPrint.isLoading}
					>
						Copy
					</Button>
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const tenants = toolSelectors.getTenants(toolStore);
	const vessels = toolSelectors.getPrintCopyModalVessels(toolStore);
	const selectedTenantOption =
		toolSelectors.getPrintCopySelectedTenantOption(toolStore);
	const selectedGroupOption =
		toolSelectors.getPrintCopySelectedGroupOption(toolStore);
	const selectedVesselOptions =
		toolSelectors.getPrintCopySelectedVesselOptions(toolStore);
	const jobToLoadVesselSpecificToTenant =
		toolSelectors.jobToLoadVesselsSpecificToTenant(toolStore);

	const currentVesselDetails = toolSelectors.getSelectedVesselOption(toolStore);

	const selectedItems = toolSelectors.getPrintSelectedItems(toolStore);
	const activeTab = toolSelectors.getActiveTabForPrint(toolStore);
	const printIdToBeCopied = toolSelectors.getActionPrintId(toolStore);
	let printList = toolSelectors.getPrintLogsList(toolStore);
	if (activeTab === PRINT_TABS.TAB_CHECKLISTS) {
		printList = toolSelectors.getPrintChecklistsList(toolStore);
	} else if (activeTab === PRINT_TABS.TAB_PERMITS) {
		printList = toolSelectors.getPrintPermitsList(toolStore);
	}
	// let itemsToBeCopied = [];
	// if (selectedItems.length) {
	// 	selectedItems.forEach((id) => {
	// 		if (id in configList.byIds) itemsToBeCopied.push(configList.byIds[id]);
	// 	});
	// } else {
	// 	if (configIdToBeCopied in configList.byIds) {
	// 		const config = configList.byIds[configIdToBeCopied];
	// 		itemsToBeCopied.push(config);
	// 	}
	// }

	const errorMessage = toolSelectors.getPrintErrorMessage(toolStore);
	const jobToCopyPrint = toolSelectors.jobToCopyPrint(toolStore);
	const groupList = toolSelectors.getToolGroupsList(toolStore);

	return {
		tenants,
		vessels,
		selectedTenantOption,
		selectedVesselOptions,
		jobToLoadVesselSpecificToTenant,
		currentVesselDetails,
		selectedItems,
		printIdToBeCopied,
		printList,
		errorMessage,
		jobToCopyPrint,
		selectedGroupOption,
		groupList,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleCopyModal() {
			dispatch(toolActions.document.printCopyModalToggle());
		},
		setTenant(tenantOption?: IOption) {
			dispatch(
				toolActions.document.toolPrintCopyTenandOptionSelect(tenantOption)
			);
		},
		setVessels(vesselOptions?: IOption[]) {
			dispatch(
				toolActions.document.toolPrintCopyVesselOptionsSelect(vesselOptions)
			);
		},
		loadTenantSpecificVesselsList(tenantId: string) {
			dispatch(
				toolActions.commands.toolTenantSpecificVesselLoad(
					tenantId,
					"print-copy"
				)
			);
		},
		setActionPrintId(printId: string) {
			dispatch(toolActions.document.toolPrintIdOnActionSelect(printId));
		},
		setSelectedItems(selectedItems: string[]) {
			dispatch(toolActions.document.toolPrintItemsSelect(selectedItems));
		},
		performCopyOperation(itemsToBeCopied: IPrintListItem[]) {
			dispatch(toolActions.commands.toolPrintCopy(itemsToBeCopied));
		},
		setErrorMessage(msg: string) {
			dispatch(toolActions.document.toolPrintErrorMessageSet(msg));
		},
		goTo(reference: string, url: string) {
			dispatch(historyActions.push(reference, url));
		},
		loadGroupsList(tenantId?: string) {
			dispatch(toolActions.commands.toolGroupsListLoad(tenantId));
		},
		setGroupOption(groupOption?: IOption) {
			dispatch(
				toolActions.document.toolPrintCopyGroupOptionSelect(groupOption)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintCopy);
