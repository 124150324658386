import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { IStore } from "redux/store";
import TableCloumns from "./TableColumns";
import ModalPortal from "components/app/_common/ModalPortal";
import Graph from "./Graph";
import useElementSize from "hooks/useElementSize";
import { IComprehensiveNoonReportFilters, IComprehensiveNoonReportGraphData, IComprehensiveNoonReportRecord } from "redux/app/report/@types";
import { sortComprehensiveNoonReportData } from "redux/app/report/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { comprehensiveNoonReportAllVesselsHeaders, comprehensiveNoonReportKeyTypes } from "redux/app/report/constants";
import moment from "moment";

type SortConfigData = {
	key: keyof IComprehensiveNoonReportRecord | "";
	direction: "ascending" | "descending";
}

type OwnProps = {
	filterLabels: IComprehensiveNoonReportFilters[];
};
type ComprehensiveNoonReportTableProps = PropsFromRedux & OwnProps;
function ComprehensiveNoonReportTable(props: ComprehensiveNoonReportTableProps) {
	const {
		rawComprehensiveNoonReportData,
		filters,
		filterLabels
	} = props;
	const [graphData, setGraphData] = useState<IComprehensiveNoonReportGraphData>({
		graphMeasurement: "mt",
		graphDataType: "",
		isBarGraph: false,
		isModalVisible: false,
	});
	const [sortConfig, setSortConfig] = useState<SortConfigData>({
		key: "",
		direction: "ascending",
	});
	const [filteredData, setFilteredData] = useState<IComprehensiveNoonReportRecord[]>(rawComprehensiveNoonReportData);

	const setIsModalVisible = (visibility: boolean) => {
		setGraphData({
			...graphData,
			isModalVisible: visibility
		});
	};

	const reportContainerRef = useRef<HTMLDivElement>(null);
	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	// Filtering
	const applyFilters = () => {
		const filteredData = rawComprehensiveNoonReportData.filter((item) => {
			return filterLabels?.every(({ key, type }) => {
				const filterValue = filters[key];
				const itemKey = key as keyof IComprehensiveNoonReportRecord
				if (!filterValue && !(type === "date")) return true;

				if (type === "text" || type === "dropdown") {
					return item[itemKey]?.toString().toLowerCase().includes(filterValue.toLowerCase());
				}
				if (type === "number") {
					return Number(item[itemKey]) >= Number(filterValue);
				}
				if (type === "date" && filters.reportDate?.startDate && filters.reportDate?.endDate) {
					const itemDate = moment(item[itemKey] as string).startOf('day');;
					const startDate = moment(filters.reportDate.startDate).startOf('day');;
					const endDate = moment(filters.reportDate.endDate).startOf('day');;

					return itemDate.isBetween(startDate, endDate, null, '[]');
				}
				return true;
			});
		});
		setFilteredData(filteredData);
	};

	useEffect(() => {
		applyFilters();
	}, [filters])

	// Sorting
	const handleSorting = (key: keyof IComprehensiveNoonReportRecord | "") => {
		setSortConfig((prev) => ({
			direction: prev.key === key && prev.direction === "ascending" ? "descending" : "ascending",
			key,
		}));
	};

	const tableRowRecords = useMemo(() => {
		return sortComprehensiveNoonReportData(
			filteredData,
			sortConfig.key,
			sortConfig.direction
		);
	}, [filteredData, sortConfig.key, sortConfig.direction]);

	return (
		<div ref={reportContainerRef}>
			<h2 className="text-lg font-medium text-gray-600 mb-3">Latest Noon Report received per vessel</h2>

			<div className="overflow-x-visible pb-4">
				<p className="text-gray-500">Total vessels: {tableRowRecords.length}</p>
				<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text border-collapse text-center">
					<thead>
						<tr>
							<th className="relative px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300">
								<div className="flex items-center justify-center space-x-2">
									<span>S. No</span>
								</div>
							</th>
							{Object.keys(comprehensiveNoonReportAllVesselsHeaders).map((headerLabel) => (
								<th
									key={headerLabel}
									className="relative cursor-pointer px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
									onClick={() => handleSorting(comprehensiveNoonReportAllVesselsHeaders[headerLabel])}
								>
									<div className="flex items-center justify-center space-x-2">
										<span>{headerLabel}</span>
									</div>

									<span className="absolute bottom-0 right-0">
										{sortConfig.key === comprehensiveNoonReportAllVesselsHeaders[headerLabel] && (
											sortConfig.direction === "ascending" ? (
												<ChevronUpIcon className="w-5 h-5 text-gray-600" />
											) : (
												<ChevronDownIcon className="w-5 h-5 text-gray-600" />
											)
										)}
									</span>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{tableRowRecords.map((row, index) => {
							return (
								<tr
									key={row._id}
									className="font-medium cursor-pointer"
								>
									<TableCloumns
										index={index}
										columns={row}
										key={row._id}
										graphData={graphData}
										setGraphData={setGraphData}
									/>
								</tr>
							);
						})}

					</tbody>
				</table>
				{!tableRowRecords.length && (
					<div className="flex justify-center items-center mt-20">
						No data found.
					</div>
				)}

			</div>

			<ModalPortal
				isActive={graphData.isModalVisible}
				onClose={() => { setIsModalVisible(false) }}
				width={900}
			>
				<div className="h-full my-12 p-6 bg-white rounded-lg max-h-[calc(90vh)] overflow-hidden font-redhat-text">
					<Graph graphData={graphData} reportContainerWidth={reportContainerWidth} />
				</div>
			</ModalPortal>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawComprehensiveNoonReportData = reportSelectors.getRawComprehensiveNoonReportData(reportStore);
	const vesselClass = reportSelectors.getGenericVesselClass(reportStore);
	const filters = reportSelectors.getGenericFilters(reportStore);

	return {
		rawComprehensiveNoonReportData,
		vesselClass,
		filters
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ComprehensiveNoonReportTable);
